/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */

import React from "react";

import { Droppable } from "react-beautiful-dnd";
import Task from "./Task";
import "./w25F2015.scss"
import PropTypes from "prop-types";
import {Typography, Divider, Icon} from "diginet-core-ui/components"
import {useTheme} from "diginet-core-ui/theme"
const {
    colors: { dark14, dark11, white },
} = useTheme();

class ColumnItem extends React.PureComponent {

    loadDetail = (taskID) => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    render() {
        const { column, tasks, isDropable, dataStageLength, index, formID, dataChangedStage, changedStage, iPermissions } = this.props;
        let maximum = formID === "W25F2016" ? dataStageLength : dataStageLength - 1;
        return (
            <div style={{ minWidth: `calc(100% / ${dataStageLength}` }}>
                <div
                    className={"display_row align-center valign-middle"}
                    style={{
                        backgroundColor: column.backgroundColor,
                        height: 40,
                        position: "relative"
                    }}
                >
                    <Typography type={"h3"} className="mgr2x" color={column.id === "REJECTED" ? "danger" : "info"}>
                        {tasks?.length || ""}
                    </Typography>
                    <Typography type={"h3"} color={"dark6"} lineClamp={1} hoverTooltip uppercase>
                        {column.title || ""}
                    </Typography>
                    {index !== maximum && (
                        index === maximum - 1 ? (
                            <Divider style={{ position: "absolute", right: 0 }} width={2} height={15} />
                        ) : (
                            <Icon name={"ArrowRight"} style={{ position: "absolute", right: 0 }} />
                        )
                    )}
                </div>
                {isDropable && (
                    <>
                    <Divider/>
                    <Droppable
                        droppableId={column.id}
                        type="COLUMN"
                        ignoreContainerClipping={true}
                        // type={column.id === 'column-1' ? 'done' : 'active'}
                    >
                        {(provided, snapshot) => {
                            return (
                                <div
                                    style={{
                                        backgroundColor: white,
                                        paddingLeft: 5,
                                        paddingTop: 5,
                                        height: "calc(100vh - 214px)",
                                    }}
                                >
                                    <div
                                        className="display_col overflow-hover"
                                        style={{
                                            height: "100%",
                                            minWidth: 290,
                                            overflow: "hidden",
                                            backgroundColor: snapshot.isDraggingOver ? dark14 : dark11,
                                        }}
                                        ref={provided.innerRef}
                                        // isDraggingOver={snapshot.isDraggingOver}
                                        {...provided.droppableProps}
                                    >
                                        {tasks.map((task, index) => {
                                            return (
                                                <Task
                                                    // dataInfoCandidate={findData}
                                                    key={task.CandidateID}
                                                    task={task}
                                                    index={index}
                                                    loadDetail={this.loadDetail}
                                                    dataChangedStage={dataChangedStage}
                                                    changedStage={changedStage}
                                                    iPermissions={iPermissions}
                                                />
                                            );
                                        })}
                                        {provided.placeholder}
                                    </div>
                                </div>
                            );
                        }}
                    </Droppable>
                    </>
                )}
            </div>
        );
    }
}
ColumnItem.propTypes = {
    column: PropTypes.any,
    tasks: PropTypes.array,
    isDropable: PropTypes.bool,
    dataStageLength: PropTypes.number,
    index: PropTypes.number,
    formID: PropTypes.string,
    dataChangedStage: PropTypes.object,
    changedStage: PropTypes.func,
    iPermissions: PropTypes.object,
};

export default ColumnItem;