/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */

import React from "react";
import Config from "../../../../../config";
import { DragDropContext } from "react-beautiful-dnd";
import ColumnItem from "./ColumnItem";
import "./w25F2015.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as W25F2015Actions from "../../../../../redux/W2X/W25F2015/W25F2015_actions";
import Api from "../../../../../services/api";
import SendMailPop from "./SendMailPop";
class DropContext extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            ...this.props.data,
            isMouseDown: false,
            isOpenSendMailPop: false,
            currentStage: null,
            dataChangedStage: {},
        };
        this.getElement = "";
        this.ref = React.createRef();
        this.startX = null;
        this.scrollLeft = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)) {
            this.setState(nextProps.data);
        }
    }

    // loadUpdateStage = async () => {
    //     const params = {
    //
    //     }
    //     const res = await Api.put("/w25f2016/update", params)
    // }

    onDragEnd = result => {
        //To do reorder our column
        const stateOld = { ...this.state };
        // if (this.props.updateTask) this.props.updateTask();
        const { tasks, columns } = this.state;
        const { destination, source, draggableId } = result;
        if (!destination) return;

        const colOld = source.droppableId;
        const colNew = destination.droppableId;
        const dataTask = tasks[columns[source.droppableId].taskIds[source.index]];
        if (dataTask?.ApproveLevel > 1 && dataTask?.ApprovalStatus === 0) return;

        if (!destination || (destination && destination.droppableId === source.droppableId && destination.index === source.index)) {
            return; //khong doi vi tri
        }

        let start = columns[source.droppableId];
        let finish = columns[destination.droppableId];

        if (start === finish) {
            // di chuyển trong cột

            let newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds,
            };

            const newState = {
                ...this.state,
                columns: {
                    ...columns,
                    [start.id]: newColumn,
                },
            };

            this.setState(newState);

            return;
        }

        //di chuyển qua cột khác

        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds,
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);

        const newFinish = {
            ...finish,
            taskIds: finishTaskIds,
        };

        const newState = {
            ...this.state,
            columns: {
                ...columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        this.setState(newState);

        if (colOld !== colNew) {
            const IsAutoSendmail = columns?.[colNew]?.IsAutoSendmail ?? 0;
            const EmailTemplate = columns?.[colNew]?.EmailTemplate ?? '';
            let params = {
                Language: Config.language || '84',
                FormID: 'W25F2016',
                StageID_new: colNew,
                StageID_old: colOld,
                RecInfoID: dataTask.RecInfoID,
                CandidateID: dataTask.CandidateID,
                IsApprovalOffer: dataTask?.IsApprovalOffer ?? 0,
                IsAutoSendmail: IsAutoSendmail,
                TemplateMailID: EmailTemplate,
            }
            if (this.props.editTask) {
                this.props.editTask(params, async (error, data) => {
                    if (error) {
                        let message = error.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        this.setState(stateOld);
                        return false;
                    }
                    const colOldOrder = columns?.[colOld]?.order ?? 0;
                    const colNewOrder = columns?.[colNew]?.order ?? 0;
                    if (data.Status === 0 && dataTask?.IsApprovalOffer === 1 && (colNew === "HIRED" || colOld === "OFFERED" || colOld === "REJECTED")) {
                        Config.popup.show("INFO", data?.Message || Config.lang("Loi_chua_xac_dinh"));
                        this.setState(stateOld);
                    } else if (data?.Status === 1 && dataTask?.IsApprovalOffer === 1) {
                        if (params?.StageID_new === "HIRED") {
                            this.props.openW25F2040Popup(dataTask);
                            this.setState(stateOld);
                        } else if ((colOld === "OFFERED" || colOld === "REJECTED") && colOldOrder > colNewOrder) {
                            Config.popup.show("YES_NO", Config.lang("Thao_tac_nay_dong_thoi_se_xoa_mat_phieu_de_xuat_Offer_cua_ung_vien._Ban_co_muon_tiep_tuc?"), async () => {
                                const res = await Api.put("/w25f2012/load-options-confirm", {...params, StageID: colNew ?? ""});
                                const resMessgage = res?.message || "";
                                if (resMessgage) {
                                    Config.popup.show("INFO", resMessgage);
                                } else {
                                    this.handleOpenPopup('W25F2303', false, {...dataTask});
                                    this.props?.loadListCandidate && this.props.loadListCandidate(0);
                                }
                            }, () => {
                                this.setState(stateOld);
                            });
                        }
                    } else {
                        if (data?.Status === 2 && dataTask?.IsApprovalOffer === 1) {
                            const res = await Api.put("/w25f2012/load-options-confirm", {...params, StageID: colNew ?? ""});
                            const resMessgage = res?.message || "";
                            if (resMessgage) {
                                Config.popup.show("INFO", resMessgage);
                            }
                        }
                        if (colOldOrder < colNewOrder && !data?.Status && IsAutoSendmail) {
                            this.handleOpenPopup('sendMail', true, {...dataTask, newStageID: colNew });
                        }
                        this.handleOpenPopup('W25F2303', false, {...dataTask});
                        this.props?.loadListCandidate && this.props.loadListCandidate(0);
                    }
                });
            }
        }
    };

    handleOpenPopup = (name, flag = false, data) => {
        switch (name) {
            case 'sendMail':
                this.setState({ isOpenSendMailPop: flag, currentStage: data ?? {} });
                break;
            case 'W25F2303':
                this.changedStage(data);
                break;
            default:
                break;
        }
    };
    changedStage = (dt) => {
        const {tasks} = this.state;
        const params = {
            FormID: "W25F2016",
            RecInfoID: dt?.RecInfoID || "",
            Language: Config.language || 84,
            CandidateID: dt?.CandidateID || ""
        };
        this.props.W25F2015Actions.moveStage(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                let dataTasks = {...tasks};
                for (let [key, value] of Object.entries(dataTasks)) {
                        if(value.CandidateID === dt?.CandidateID) {
                            dataTasks[key] = {...value, IsNotInterview: data?.[0]?.IsNotInterview ?? 0};
                        }
                }
                this.setState({dataChangedStage: data?.[0], tasks: dataTasks });
            }
        });
    };

    loadDetail = taskID => {
        if (this.props.loadDetail) this.props.loadDetail(taskID);
    };

    onMouseDown = e => {
        this.setState({ isMouseDown: true });
        this.startX = e.pageX - this.ref.current.offsetLeft;
        this.scrollLeft = this.ref.current.offsetLeft;
    };

    onMouseLeave = () => {
        this.setState({ isMouseDown: false });
    };

    onMouseUp = () => {
        this.setState({ isMouseDown: false });
    };

    onMouseMove = e => {
        const { isMouseDown } = this.state;
        if (!isMouseDown) return;
        e.preventDefault();
        const x = e.pageX - this.ref.current.offsetLeft;
        const walk = (x - this.startX) * 1.2;
        this.ref.current.scrollLeft = this.scrollLeft - walk;
    };

    render() {
        const innerWidth = window.innerWidth;
        const min = innerWidth < 1500 ? 1500 : innerWidth - 80;
        const { getColumns, dataStageLength, iPermissions } = this.props;
        const { tasks, columns, columnOrder, isMouseDown,
                isOpenSendMailPop, currentStage, dataChangedStage } = this.state;
        let obj = {
            tasks,
            columns,
            columnOrder,
        };
        getColumns(obj);
        return (
            <>
                <div ref={this.ref}
                    onMouseLeave={this.onMouseLeave}
                    onMouseUp={this.onMouseUp}
                    onMouseDown={this.onMouseDown}
                    onMouseMove={this.onMouseMove}
                    style={{ minWidth: "100%", overflow: "auto", userSelect: "none", cursor: isMouseDown ? "grabbing" : "grab"}}>
                    <div style={{ display: "inline-flex", minWidth: min }}>
                        <DragDropContext
                            onDragEnd={this.onDragEnd}
                        >
                            {columnOrder && columnOrder.map((columnId, idx) => {
                                const column = { ...columns }[columnId];
                                if (!column || !column.taskIds) return null;
                                const tasksL = column.taskIds.map(taskId => tasks[taskId]);
                                return <ColumnItem
                                    isDropable={true}
                                    key={column.id}
                                    index={idx + 1}
                                    column={column}
                                    tasks={tasksL}
                                    loadDetail={this.loadDetail}
                                    indexTab={0}
                                    dataStageLength={dataStageLength}
                                    formID={"W25F2016"}
                                    dataChangedStage={dataChangedStage}
                                    changedStage={this.changedStage}
                                    iPermissions={iPermissions}
                                />

                            })}
                        </DragDropContext>
                    </div>
                </div>
                {isOpenSendMailPop && <SendMailPop open={isOpenSendMailPop} currentStage={currentStage} handleOpenPopup={this.handleOpenPopup} />}
            </>
        )
    }
}
DropContext.propTypes = {
    getColumns: PropTypes.any, // callback to get new value
    dataStageLength: PropTypes.number,
    editTask: PropTypes.func,
    loadDetail: PropTypes.func,
    data: PropTypes.object,
    iPermissions: PropTypes.object,
};
export default connect(null, dispatch => ({
    W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch),
}))(DropContext);
