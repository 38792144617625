/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup, Col, Row}         from "react-bootstrap";
import Config                        from "../../../../../config";
import {Combo, TextField}            from "../../../../common/form-material";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../../redux/W2X/W25F2011/W25F2011_actions";
import Modal                         from "../../../../common/modal/modal";
import ButtonGeneral                 from "../../../../common/button/button-general";
import LabelText                     from "../../../../common/label-text/label-text";
import MForm                         from "../../../../common/form-material/form";
import _                             from "lodash";
import NumberFormat                  from "react-number-format";

const dataCboExpectationPoint = [
    {
        ID: 1,
        Name: "1/5"
    },
    {
        ID: 2,
        Name: "2/5"
    },
    {
        ID: 3,
        Name: "3/5"
    },
    {
        ID: 4,
        Name: "4/5"
    },
    {
        ID: 5,
        Name: "5/5"
    },
];
const TextFieldCustom = (props) => {
    return <TextField margin={"normal"} {...props} />
};
class W25F2011AddCriteria extends React.PureComponent {
    constructor(props) {
        super(props);
        const data = props && props.rowData ? props.rowData : null;
        this.state = {
            EvaluationElementID: data && data.EvaluationElementID ? data.EvaluationElementID : "",
            EvaluationElementName: data && data.EvaluationElementName ? data.EvaluationElementName : "",
            ExpectationPoint: data && data.ExpectationPoint ? data.ExpectationPoint : "",
            Proportion: data && (data.Proportion || data.Proportion === 0) ? data.Proportion : "",
            error: {},
            loading: false,
            mode: "add",
            dataCboCriteria: []
        };
        this.Proportion = null;
        this.isSaved = false;
        this.dataCboCriteria = [];
    }

    loadCboCriteria = () => {
        const {dataForm, listEvaluationElementID} = this.props;
        const params = {
            RecPositionID: dataForm && dataForm.RecPositionID ? dataForm.RecPositionID : ""
        };
        this.setState({loading: true});
        this.props.W25F2011Actions.getCboCriteria(params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.dataCboCriteria = data && data.filter(d => !listEvaluationElementID.includes(d.EvaluationElementID));
                this.setState({
                    dataCboCriteria: this.dataCboCriteria
                });
            }
        });
    };

    componentDidMount () {
        const {mode, rowData: data, listEvaluationElementID} = this.props;
        this.isSaved = false;
        this.dataCboCriteria = this.dataCboCriteria && this.dataCboCriteria.filter(d => !listEvaluationElementID.includes(d.EvaluationElementID));
        this.setState({
            EvaluationElementID: data && data.EvaluationElementID ? data.EvaluationElementID : "",
            EvaluationElementName: data && data.EvaluationElementName ? data.EvaluationElementName : "",
            ExpectationPoint: data && data.ExpectationPoint ? data.ExpectationPoint : "",
            Proportion: data && (data.Proportion || data.Proportion === 0) ? data.Proportion : "",
            mode: mode,
            error: {},
            dataCboCriteria: this.dataCboCriteria
        });
        this.loadCboCriteria();
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.rowData) !== JSON.stringify(this.props.rowData)) {
            const data = this.props.rowData;
            this.isSaved = false;
            this.setState({
                EvaluationElementID: data && data.EvaluationElementID ? data.EvaluationElementID : "",
                EvaluationElementName: data && data.EvaluationElementName ? data.EvaluationElementName : "",
                ExpectationPoint: data && data.ExpectationPoint ? data.ExpectationPoint : "",
                Proportion: data && (data.Proportion || data.Proportion === 0) ? data.Proportion : "",
                mode: this.props.mode,
                error: {},
            });
        }
        if (JSON.stringify(prevProps.listEvaluationElementID) !== JSON.stringify(this.props.listEvaluationElementID)) {
            const {listEvaluationElementID} = this.props;
            this.dataCboCriteria = this.dataCboCriteria && this.dataCboCriteria.filter(d => !listEvaluationElementID.includes(d.EvaluationElementID));
            this.setState({
                dataCboCriteria: this.dataCboCriteria
            });
        }
    }

    setStateErrorText = (value) => {
        const {error} = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = (isContinue) => {
        const {RecInfoID} = this.props.dataForm;
        const {mode, EvaluationElementID, ExpectationPoint, Proportion} = this.state;
        const validateRules = [
            {
                name: "EvaluationElementID",
                rules: "isRequired",
                value: EvaluationElementID
            },
            {
                name: "ExpectationPoint",
                rules: "isRequired",
                value: ExpectationPoint
            },
            {
                name: "Proportion",
                rules: "isRequired",
                value: Proportion
            },
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false;
        const params = {
            RecInfoID: RecInfoID || "",
            EvaluationElementID: EvaluationElementID ? EvaluationElementID : "",
            ExpectationPoint: ExpectationPoint ? Number(ExpectationPoint) : 0,
            Proportion: Proportion ? Number(Proportion) : 0
        };
        this.setState({loading: true});
        this.props.W25F2011Actions[mode === "edit" ? "updateCriteria" : "saveCriteria"](params, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.isSaved = true;
            if (isContinue) {
                Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.resetForm(EvaluationElementID);
            } else {
                Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                this.onClose();
            }
        });
    };

    resetForm = (EvaluationElementID) => {
        this.dataCboCriteria = this.dataCboCriteria.filter(d => d.EvaluationElementID !== EvaluationElementID);
        this.setState({
            EvaluationElementID: "",
            ExpectationPoint: "",
            Proportion: "",
            mode: "add",
            error: {},
            dataCboCriteria: this.dataCboCriteria
        }, () => {
            // if (this.Proportion) this.Proportion.instance.option('value', "");
        });
    };

    onClose = () => {
        const {onClose} = this.props;
        this.resetForm();
        if (onClose) onClose(null, null, this.isSaved);
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const value = _.get(e,"value", _.get(e,"target.value", ""));
        this.setState({[key]: value});
    };

    render() {
        const { open, dataForm } = this.props;
        const {mode, loading, error, dataCboCriteria, EvaluationElementID, EvaluationElementName, ExpectationPoint, Proportion} = this.state;
        let _dataCboCriteria = [...dataCboCriteria];
        if (mode === "edit") {
            const criteria = _dataCboCriteria.find(d => d.EvaluationElementID === EvaluationElementID);
            if (!criteria) {
                _dataCboCriteria.unshift({
                    EvaluationElementID,
                    EvaluationElementName
                });
            }
        }

        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"sm"} fullWidth={true}
                    title={Config.lang("DHR_Them_tieu_chi_danh_gia")}
                    onClose={this.onClose}
                >
                    <Modal.Content>
                        <div className={""}>
                            <FormGroup style={{marginBottom: 5}}>
                                <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('DHR_Tieu_chi')}&nbsp;<span style={{color: "red"}}>*</span></span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Combo
                                            error={error && error['EvaluationElementID']}
                                            dataSource={Config.storeDataSoureDevExtreme(_dataCboCriteria)}
                                            displayExpr={'EvaluationElementName'}
                                            valueExpr={'EvaluationElementID'}
                                            margin={"normal"}
                                            value={EvaluationElementID || null}
                                            disabled={loading || (mode === "edit" && !this.isSaved)}
                                            stylingMode={'underlined'}
                                            placeholder={Config.lang('DHR_Chon')}
                                            onValueChanged={(e) => this.handleChange("EvaluationElementID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: 5}}>
                                <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('DHR_Diem_ky_vong')}&nbsp;<span style={{color: "red"}}>*</span></span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <Combo
                                            error={error && error['ExpectationPoint']}
                                            dataSource={dataCboExpectationPoint}
                                            displayExpr={'Name'}
                                            valueExpr={'ID'}
                                            margin={"normal"}
                                            disabled={loading}
                                            value={ExpectationPoint || null}
                                            stylingMode={'underlined'}
                                            placeholder={Config.lang('DHR_Chon')}
                                            onValueChanged={(e) => this.handleChange("ExpectationPoint", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: 5}}>
                                <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('DHR_Trong_so')}&nbsp;<span style={{color: "red"}}>*</span></span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <NumberFormat
                                            fullWidth
                                            placeholder={Config.lang('DHR_Nhap')}
                                            error={error && error['Proportion']}
                                            ref={ref => this.Proportion = ref}
                                            defaultValue={String(Proportion) || ""}
                                            customInput={TextFieldCustom}
                                            // thousandSeparator={true}
                                            isNumericString={true}
                                            allowNegative={false}
                                            required
                                            value={String(Proportion) || ""}
                                            onValueChange={e => this.handleChange("Proportion", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className={"display_row align-center"}>
                                    <Col xs={12} sm={4} md={4} lg={4}>
                                        <span className={"mgb0"}>{Config.lang('DHR_Tieu_de_tin_tuyen_dung')}</span>
                                    </Col>
                                    <Col xs={12} sm={8} md={8} lg={8}>
                                        <LabelText
                                            label={""}
                                            value={dataForm && dataForm.RecInfoTitle}
                                            fullWidth={true}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align_center valign-bottom"}>
                                <ButtonGeneral name={Config.lang('DHR_Luu')}
                                               typeButton={'save'}
                                               color={"primary"}
                                               className={"mgr5"}
                                               variant={"outlined"}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               disabled={loading}
                                               onClick={this.onSave}/>
                                <ButtonGeneral name={Config.lang('DHR_Luu_va_nhap_tiep')}
                                               typeButton={'save'}
                                               style={{textTransform: 'uppercase'}}
                                               size={"large"}
                                               disabled={loading}
                                               onClick={() => this.onSave(true)}/>
                            </FormGroup>
                        </div>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        );
    }

}

W25F2011AddCriteria.propTypes = {
    open: PropTypes.bool,
    rowData: PropTypes.object,
    dataForm: PropTypes.object,

    onClose: PropTypes.func,
};

export default compose(connect((state) => ({
    getCboCriteria: state.W25F2011.getCboCriteria,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
})))(W25F2011AddCriteria);