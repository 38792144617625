/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";

import Config from '../../../../../config';
import W25F2040 from "../../W25F2040/W25F2040";
import W84F3005 from "../../../../W8X/W84/W84F3005/W84F3005"
import W25F2303 from "../../W25F2303/W25F2303";

import * as generalActions from "../../../../../redux/general/general_actions";
import * as W25F2015Actions from "../../../../../redux/W2X/W25F2015/W25F2015_actions";

import _ from 'lodash';
import moment from "moment";
import {withStyles} from '@material-ui/core';
import {useTheme} from 'diginet-core-ui/theme';
import Icon, {ReplaceAllRight, Info} from "diginet-core-ui/icons";
import {Tooltip, Rating, Typography, ButtonIcon, Avatar} from "diginet-core-ui/components";

const {
    colors:{
        dark5,
        secondary,
        white
    }
} = useTheme();

const styles = () => ({
    iconMove:{
        // background: "grey",
        // padding: "2px 4px",
        display:"flex",
        alignItems:'center',
        justifyContent:"center",
        width:24,
        height:24,
        borderRadius: "24px 0 0 24px",
        color: "white",
        background: secondary
    },
    iconButton: {
        display:"flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        cursor: "pointer"
    }
});

class ItemTemplate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW25F2040Popup: false,
            showW84F3005Popup: false,
        };
    }

    renderUserImage = () => {
        const {data} = this.props;
        if (_.isEmpty(data)) return false;
        const {ListMemberFollow} = data;
        const listMember = ListMemberFollow.split(",");
        // get list user by UserID
        const listUsers = Config.getListUser(listMember, "UserID");

        let styleCustom = {
            minHeight: 46
        };
        if(listUsers.length > 1){
            styleCustom = {
                width: 35,
                minWidth: 35,
                height: 35,
                minHeight: 35,
            }
        }
        return <>
            {_.map(_.slice(listUsers, 0, 2), (item, i) => <Avatar
                readOnly
                key={i}
                data={{
                    [Config.lang('Nhan_vien')]: `${item.EmployeeID} - ${item.EmployeeName}`,
                    [Config.lang('Phong_ban')]: item.DepartmentName,
                    [Config.lang('Du_an')]: item.DutyName,
                    [Config.lang('Ngay_vao_lam')]: item.dateJoined,
                }}
                style={{
                    ...styleCustom,
                    marginRight: i*6 - 6,
                    zIndex: 2-i,}}
                src={item.UserPictureURL}
                width={styleCustom.width}
                height={styleCustom.height}
                hoverAble
            />)}
            {_.size(listUsers) > 2 && <Icon name="MoreHoriz" width={15} color={dark5}/>}
        </>
    };
    closePopup = (key, isSave = false) => {
        if (key === "showW84F3005Popup") {
            this.setState({ showW84F3005Popup: false });
        }
        if (key === "showW25F2040Popup") {
            this.setState({ showW25F2040Popup: false });
        }
    };
    render() {
        const {data, onClick, classes, open, close, showW25F2303Popup, dataChangedStage, changedStage, iPermissions} = this.props;
        if (_.isEmpty(data)) return false;
        let obj = {
            CandidatePictureURL: Config.getUserPicture(data.CandidatePictureURL && data.CandidatePictureURL) || require('../../../../../assets/images/general/user_default.svg'),
            CandidateName: data.CandidateName || "",
            Sex: data.SexName || "",
            BirthDate: data.BirthDate ? moment(data.BirthDate).format("DD/MM/YYYY") : "",
            Mobile: data.Mobile || "",
            EPointAvg: Number.isInteger(data.EPointAvg) ? data.EPointAvg : (Math.ceil(data.EPointAvg) - 0.5) || 0,
            ListMemberFollow: data.ListMemberFollow && data.ListMemberFollow.split(",").length > 2,
            count: data.count || 0,
        };
        const isMoved = !!_.get(data, "IsMoved", 0);
        const isEmployee = !!_.get(data, "IsEmployee", 0);
        const IsApproved = !!_.get(data, "IsApproved", 0);
        const ApprovalStatus = data?.ApprovalStatus;
        const ApproveLevel = data?.ApproveLevel;
        const showIcon = !_.isEmpty(dataChangedStage) ?
            (data?.CandidateID === dataChangedStage?.CandidateID ?
                !!dataChangedStage?.IsNotInterview : !!_.get(data, "IsNotInterview", 0)) :
            !!_.get(data, "IsNotInterview", 0);
        const dataPopup = {
            CandidateID: data?.CandidateID || "",
            InterviewFileID: data?.InterviewFileID || "",
            Language: Config.language || 84,
            RecInfoID: data?.RecInfoID || ""
        };
        return (
            <div onMouseDown={e => {e.stopPropagation()}}
                 style={{height: "100%", width: "100%", position: "relative"}}
                 className={'display_col align-between'}
            >
                <W84F3005
                    open={this.state.showW84F3005Popup}
                    onClose={() => {
                        this.closePopup("showW84F3005Popup");
                    }}
                    TransID={data?.DecisionID || ""}
                />
                <W25F2040
                    open={this.state.showW25F2040Popup}
                    onClose={() => {
                        this.closePopup("showW25F2040Popup");
                    }}
                    RecInfoID={data?.RecInfoID || ""}
                    CandidateID={data?.CandidateID || ""}
                    TransID={data?.DecisionID || ""}
                    mode={1}
                    readOnly={ApproveLevel !== 1 || ApprovalStatus !== 0}
                    fullCandidateInfo={data || {}}
                    iPermissions={iPermissions}
                />
                {showW25F2303Popup ? <W25F2303
                    open={showW25F2303Popup}
                    data={dataPopup}
                    mode={"add"}
                    onClose={close}
                    FormID="W25F2023"
                    disableIconSave={true}
                    changedStage={changedStage}
                /> : ""}
                <div className={'display_row align-left'}
                     style={{position: 'relative'}}>
                    <Avatar
                        className={"mgr10"}
                        width={40}
                        height={40}
                        src={obj.CandidatePictureURL}
                        onClick={onClick}
                        readOnly
                    />
                    <div className={'display_col'}>
                        <Typography type={'h3'}
                                    onClick={onClick}
                                    style={{cursor: "pointer"}}
                        >
                            {obj.CandidateName}
                        </Typography>
                        <Typography type={'p1'}
                                    className={'mht5'}
                                    color={dark5}>
                            {obj.Sex}, {obj.BirthDate}
                        </Typography>
                        <Typography type={'p1'}
                                    className={'mht5'}
                                    color={dark5}>
                            {obj.Mobile}
                        </Typography>
                    </div>
                    <div className={'display_row align-center valign-bottom'} style={{flex:1}}>
                        {this.renderUserImage()}
                    </div>
                    {showIcon ?
                        <div style={{
                            position:"absolute",
                            top: 20,
                            left: 8,
                            backgroundColor: white,
                            borderRadius: '50%'
                        }}
                        >
                            <Tooltip title={Config.lang("Ung_vien_chua_co_lich_phong_van")}>
                                <ButtonIcon
                                    name={"CalendarBlankRounded"}
                                    color={"danger"}
                                    viewType={'text'}
                                    circular
                                    size={'tiny'}
                                    onClick={open}
                                    viewBox
                                />
                            </Tooltip>
                        </div>
                        : ""
                    }
        </div>
                <div className="display_row align-center">
                    <div className="align-center display_row" style={{width: 50}}>
                        <Typography type={'h3'} color={'info'}>
                            {obj.count}
                        </Typography>
                        <Icon name={"PersonAccept"}/>
                    </div>
                    <Rating
                        quantity={5}
                        value={obj?.EPointAvg ?? 0}
                        half={true}
                        readOnly
                        color={"warning"}
                    />
                    <div className={'display_row valign-bottom'} style={{flex:1}}>
                        {IsApproved && (
                            <ButtonIcon name={'History'}
                                        viewType={'text'}
                                        circular
                                        className={'mgr5'}
                                        size={'small'}
                                        onClick={() => this.setState({ showW84F3005Popup: true })}
                            />
                        )}
                        {IsApproved && (
                            <ButtonIcon name={(ApprovalStatus === 0 || ApprovalStatus === 1) ? 'Approval' : 'Cancel'}
                                        color={ApprovalStatus === 1 ? 'info' : (ApprovalStatus === 2 ? 'danger' : undefined)}
                                        size={'small'}
                                        viewType={'text'}
                                        circular
                                        onClick={() => {
                                            iPermissions?.W25F2040 > 0 && this.setState({ showW25F2040Popup: true });}
                                        }
                            />
                        )}
                    </div>
                    <Tooltip
                        title={
                            isMoved
                                ? Config.lang("Da_chuyen")
                                : isEmployee
                                ? Config.lang(
                                    "Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay"
                                )
                                : ""
                        }
                        arrow
                        direction={"down"}
                        tooltipMaxWidth={300}
                        style={{ position: "absolute", bottom: 37, right: -15 }}
                    >
                        <div className={isMoved || isEmployee ? classes.iconMove : ""}>
                            {isMoved ? <ReplaceAllRight color={"white"} width={20} /> : isEmployee ? <Info color={"white"} width={20} /> : ""}
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

ItemTemplate.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.object,
    open: PropTypes.func,
    close: PropTypes.func,
    showW25F2303Popup: PropTypes.bool,
    dataChangedStage: PropTypes.object,
    changedStage: PropTypes.func,
    iPermissions: PropTypes.object,
};

export default compose(
    connect(null,
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(ItemTemplate);