/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/18/2020
 * @Example
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import * as W25F2015Actions from "../../../../redux/W2X/W25F2015/W25F2015_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import moment from "moment";
import _ from "lodash";
import { browserHistory } from "react-router";
import W25F2015WorkFlow from "./W25F2015WorkFlow";
import Status from "../../../common/status/status";
import W25F2017Candidate from "./W25F2017Candidate";
import W25F2018 from "../W25F2018/W25F2018";
import W25F6000 from "../W25F6000/W25F6000";
import W25F2303 from "../W25F2303/W25F2303";
import W25F2022 from "../W25F2022/W25F2022";
import W25F1000 from "../W25F1000/W25F1000";
import W25F2040 from "../W25F2040/W25F2040";
import {Loading} from "../../../common/loading/loading";
import {
    Button,
    DateRangePicker,
    Dropdown,
    DropdownBox,
    Col,
    Row,
    ButtonIcon,
    Typography,
    Popover,
    PopoverBody,
    Tooltip,
    TabContainer,
    TabItem,
    TabPanel,
    TabHeader,
    Icon,
    Divider
} from "diginet-core-ui/components";
import {useTheme} from "diginet-core-ui/theme";
import * as W25F2018Actions from "../../../../redux/W2X/W25F2018/W25F2018_actions";

const {spacing} = useTheme();

const styles = theme => ({
    divIconItem: {
        height: 30,
        width: 30,
        padding: "0 5px",
        whiteSpace: "nowrap",
    },
    divIcon: {
        right: "50px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        width: "120px",
        height: "36px",
        padding: 0,
        zIndex: 999,
        position: "fixed",
        background: "#FFFFFF",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
        listStyle: "none",
        marginTop: "-17px",
        borderRadius: "4px",
    },
    transform: {
        // minWidth: "100vw",
        "& .react-swipeable-view-container": {
            transform: "none !important",
            willChange: "auto !important",
        },
    },
    buttonAction: {
        [theme.breakpoints.down(820)]: {
            flexDirection: "column",
        },
    },
    buttonGeneral: {
        [theme.breakpoints.down(820)]: {
            paddingBottom: 5,
        },
    },
    statusCircle: {
        textAlign: "center",
        "& .MuiChip-label": {
            display: "none",
        },
        "& .MuiChip-root": {
            padding: "0px !important",
            height: 20,
            "& .MuiChip-icon": {
                margin: "0px !important",
            },
        },
    },
    statusText: {
        "& .MuiChip-root": {
            padding: "0px !important",
        },
        "& .MuiChip-label": {
            paddingLeft: 0,
        },
    },
    loading: {
        height: "100%",
        width: "100%",
        display: "inline-flex",
    },
    customFilter: {
        width: 600,
        marginLeft:'auto'
    }
});

class W25F2015 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popOverCustom: null,
            popOverStatus: null,
            indexTab: 0,
            loadInfoEmployment: false,
            loadStage: false,
            loadListCandidate: false,
            dataEmployment: {},
            dataInfoCandidate: [],
            dataStage: [],
            dataListCandidate: [],
            dataTemp: {},
            mode: "add",
            CandidateID: "",
            dataEditCandidate: {},
            showW25F6000Popup: false,
            W25F2017dataListCandidate: [],
            disabledAdd: false,
            disabledView: false,
            dataQuickView: [],
            showW25F1000Popup: false,
            showW25F2303Popup: false,
            showW25F2040Popup: false,
            DecisionID: "",
            dateRange: [null, null],
            loadingCboFilterCandidate: false,
            loadingCboInterview: false,
            loadingCboStage: false,
            loadingDataGrid: false,
            dataFilter: {},
            requiredFields: {},
    };
    this.dataFilter= {
        CandidateID: "",
        CanConfirmStatusID: "",
        InterviewStatusID: "",
        StageID: "",
        InterviewType: "",
        InterviewerID: "",
        InterviewDateFrom: null,
        InterviewDateTo: null,
    };
    this.interviewType = [
        {interviewTypeID : "ONSITE", interviewTypeNameVI: "Phỏng vấn tại văn phòng", interviewTypeNameEN: "ONSITE"},
        {interviewTypeID : "VIDEO", interviewTypeNameVI: "Phỏng vấn online", interviewTypeNameEN: "VIDEO"},
        {interviewTypeID : "PHONE", interviewTypeNameVI: "Phỏng vấn qua điện thoại", interviewTypeNameEN: "PHONE"},
    ];
    this.canConfirmStatus = [
        {CanConfirmStatusID : "Yes", CanConfirmStatusName:  "Yes"},
        {CanConfirmStatusID : "Maybe", CanConfirmStatusName: "Maybe"},
        {CanConfirmStatusID : "No", CanConfirmStatusName: "No"},
    ];
    this.interviewStatus = [
        {InterviewStatusID : "Scheduled",  InterviewStatusName: "Scheduled"},
        {InterviewStatusID : "Finished", InterviewStatusName: "Finished"},
        {InterviewStatusID : "Cancel", InterviewStatusName: "Cancel"},
    ];
        this.dataTemp = {};
        this.workflow = null;
        this.ref2018 = null;
        this.isRenderModalW25F6000 = false;
        this.filter = {
            limit: 10,
            skip: 0
        };
    }

    onBack = () => {
        const {FormID} = this.getInfo();
        if(FormID === 'W25F2010') browserHistory.goBack();
        else browserHistory.push(Config.getRootPath() + "W25F2010");
    };

    handleClick = (event, stateName) => {
        this.setState({ [stateName]: event.currentTarget });
    };

    handleClose = stateName => {
        this.setState({ [stateName]: null });
    };

    getInfo = flag => {
        const { location } = this.props;
        // return { RecInfoID: "FBB36AD2-F247-4E52-9A9B-26E885F6F8F2" }
        if (location && location.state) {
            const voucher_id = location.state?.voucher_id ? location.state.voucher_id : "";
            const voucher_no = location.state?.voucher_no ? location.state.voucher_no : "";
            return {
                RecInfoID: location.state.RecInfoID || voucher_id,
                RecInfoTitle: location.state.RecInfoTitle || voucher_no,
                FormID: location.state.FormID,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W25F2010");
                return null;
            }
            return false;
        }
    };

    loadInfoEmployment = async () => {
        if (this.state.loadInfoEmployment === false) this.setState({ loadInfoEmployment: true });
        const { RecInfoID } = this.getInfo();
        const params = {
            RecInfoID: RecInfoID,
            // "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            Language: Config.language || "84",
        };
        await this.props.W25F2015Actions.getLoadInfoEmployment(params, (error, data) => {
            this.setState({ loadInfoEmployment: false });
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                // const dataEmployment = data.map(data => { return ({ ...data }) });
                this.setState(
                    {
                        dataEmployment: data,
                    }
                    // , () => {
                    //   this.oldDataEmployment = this.state.dataEmployment.map(item => ({ ...item }));
                    // }
                );
            }
        });
    };


    loadStages = async () => {
        const { RecInfoID } = this.getInfo();
        const params = {
            RecInfoID: RecInfoID,
            // "46765BD2-56F7-43BC-81B1-9AF2912D846F",
        };
        if (this.state.loadStage === false) this.setState({ loadStage: true });
        await this.props.W25F2015Actions.loadStage(params, (error, data) => {
            this.setState({ loadStage: false });
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data.length > 0) {
                this.setState({
                    dataStage: data,
                });
            } else {
                this.setState({ disabledAdd: true, disabledView: true });
            }
        });
    };

    loadListCandidates = async (mode, isLoaded = false) => {
        const { RecInfoID } = this.getInfo();
        const params = {
            FormID: mode === 1 ? "W25F2017" : "W25F2016",
            RecInfoID: RecInfoID,
            // "46765BD2-56F7-43BC-81B1-9AF2912D846F",
            Language: Config.language || "84",
            Mode: 0 || mode,
        };
        if (this.state.loadListCandidate === false) this.setState({ loadListCandidate: true });
        await this.props.W25F2015Actions.getListCandidate(params, (error, data) => {
            this.setState({ loadListCandidate: false });
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (mode === 1) {
                this.setState(
                    {
                        W25F2017dataListCandidate: data,
                    },
                    () => {
                        if (isLoaded) {
                            this.loadListCandidates(0);
                        }
                    }
                );
            }
            if (mode === 0) {
                this.setState({
                    dataListCandidate: data,
                });
            }
            if (data.length > 0) {
                this.setState({ dataQuickView: data });
            }
        });
    };

    loadCboFilterCandidate = () => {
        const {dataEmployment} = this.state;
        const params = {
            RecInfoID: dataEmployment?.RecInfoID ?? "",
        };
        this.setState({loadingCboFilterCandidate: true});
        this.props.w25F2018Actions.getCboFilterCandidate(params,(errors) => {
            this.setState({loadingCboFilterCandidate: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboInterview = () => {
        this.setState({loadingCboInterview: true});
        this.props.w25F2018Actions.getCboInterview((errors) => {
            this.setState({loadingCboInterview: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadCboStage = () => {
        const {dataEmployment} = this.state;
        const params = {
            RecInfoID: dataEmployment?.RecInfoID ?? "",
        };
        this.setState({loadingCboStage: true});
        this.props.w25F2018Actions.getCboStage(params,(errors) => {
            this.setState({loadingCboStage: false});
            if (errors) {
                Config.popup.show('ERROR', errors);
                return false;
            }
        });
    };

    loadRequiredFields = () => {
        const params ={
            FormID:"W25F2015",
            ModuleID:"25",
            CheckMode:0
        }
        this.props.generalActions.getRequiredFields(params, (error, data) => {
            if(error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    requiredFields: data
                });
            }
        })
    }

    componentDidMount = async () => {
        if (!this.getInfo(true)) return;
        await this.loadInfoEmployment();
        await this.loadStages();
        await this.loadListCandidates(0);
        await this.loadRequiredFields();
    };

    renderStatus = (data, flag) => {
        const { classes } = this.props;
        let color = {
            PUBLIC: "success",
            DRAFT: "",
            INTERNAL: "warning",
            CLOSE: "danger",
        };
        if (!_.isEmpty(data)) {
            if (flag) {
                let status = {
                    AppStatusIcon: <i className={"fas fa-circle"} style={{fontSize: 24}}/>,
                    AppStatusColor: color[data.StatusID.toUpperCase()],
                };
                return <Status data={status} className={classes.statusCircle} />;
            } else {
                let status = {
                    AppStatusName: data.StatusName,
                    AppStatusColor: color[data.StatusID.toUpperCase()],
                };
                return <Status data={status} className={classes.statusText} />;
            }
        }
    };

    showInfoEmployment = () => {
        const { dataEmployment, dataListCandidate } = this.state;
        const datefrom = dataEmployment && moment(dataEmployment.ReceiveDateFrom).format("DD/MM/YYYY");
        const dateto = dataEmployment && moment(dataEmployment.ReceiveDateTo).format("DD/MM/YYYY");
        const total = dataListCandidate && dataListCandidate.length;
        return (
            <>
                {dataEmployment && (
                    <Popover
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        trigger={"hover"}
                        anchor={
                            <ButtonIcon
                                name="MoreHoriz"
                                viewType={"ghost"}
                                className={"mgr2x"}
                                size={'large'}
                            />
                        }
                        >
                        <PopoverBody style={{maxWidth: 300}}>
                            <Row>
                                <Col xs={2} className={"display_row align-center valign-middle"}/>
                                <Col xs={10}>
                                    <Typography>{dataEmployment.RecInfoCode}</Typography>
                                </Col>
                                <Col xs={2} className={"display_row align-center valign-middle"}>
                                    <Icon name={"calendar"} height={18} width={18}/>
                                </Col>
                                <Col xs={10}>
                                    <Typography>{datefrom} - {dateto}</Typography>
                                </Col>
                                <Col xs={2} className={"display_row align-center valign-middle"}>
                                    {this.renderStatus(dataEmployment, true)}
                                </Col>
                                <Col xs={10}>
                                    {this.renderStatus(dataEmployment)}
                                </Col>
                                <Col xs={2} className={"display_row align-center valign-middle"}>
                                    <Typography>{total}</Typography>
                                </Col>
                                <Col xs={10}>
                                    <Typography>{Config.lang("Ung_vien")}</Typography>
                                </Col>
                            </Row>
                        </PopoverBody>
                    </Popover>
                )}
            </>
        );
    };

    showListAction = () => {
        const { dataEmployment } = this.state;
        const { RecInfoID } = this.getInfo();
        return (
            <Popover
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                anchor={<ButtonIcon name="ArrowDown" viewType={"text"} circular className={"mgl1x"} />}
            >
                <PopoverBody>
                    <div className={"display_row"}>
                        <Tooltip title={Config.lang("Chinh_sua_thong_tin_tuyen_dung")} style={{ width: "fit-content" }}>
                            <Button
                                label={Config.lang("Chinh_sua_thong_tin_tuyen_dung")}
                                onClick={() => {
                                    this.handleClose("popOverCustom");
                                    browserHistory.push({
                                        pathname: Config.getRootPath() + "W25F2011",
                                        state: {
                                            mode: "edit",
                                            RecInfoID,
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={Config.lang("Export_danh_sach_ung_vien")} style={{ width: "fit-content" }}>
                            <Button
                                label={Config.lang("Export_danh_sach_ung_vien")}
                                onClick={() => {
                                    this.handleClose("popOverCustom");
                                }}
                            />
                        </Tooltip>
                    </div>
                    <Tooltip title={Config.lang("Xem_tren_website_tuyen_dung")} style={{ width: "fit-content" }}>
                            <Button
                                label={Config.lang("Xem_tren_website_tuyen_dung")}
                                onClick={() => {
                                    this.handleClose("popOverCustom");

                                    //check Slug, if null then show message
                                    if (!dataEmployment?.Slug) {
                                        Config.popup.show("INFO", Config.lang("Tin_chua_duoc_dang_cong_khai"));
                                        return;
                                    }

                                    //check Slug, if exits then open new tab browser
                                    let url = Config.getSetting("RECRUITMENT_URL");
                                    if (url[url.length - 1] === "/") url = url.slice(0, -1);
                                    url += "/Recruiment-detail/" + dataEmployment.Slug;

                                    window.open(url);
                                }}
                            />
                        </Tooltip>
                </PopoverBody>
            </Popover>
        );
    };

    handleChanged = (event, value) => {
        const { loadListCandidate, W25F2017dataListCandidate, dataListCandidate } = this.state;
        if (!loadListCandidate) {
            if (W25F2017dataListCandidate.length < dataListCandidate.length && value !== 1) {
                this.loadListCandidates(0);
            }
            this.setState({ indexTab: value });
        }
        if(value === 0) {
            this.loadListCandidates(0);
        }
    };

    dataQuickView = () => {
        this.setState({ showW25F2022Popup: true });
    };
    onAdd = () => {
        this.setState({ showW25F1000Popup: true, mode: "add" });
    };

    onEdit = e => {
        this.setState({
            showW25F1000Popup: true,
            mode: "edit",
            CandidateID: _.get(e, "data.CandidateID", ""),
        });
    };

    onFilter = () => {
        this.isRenderModalW25F6000 = true;
        this.setState({ showW25F6000Popup: true });
    };

    onChosen = data => {
        const { RecInfoID } = this.getInfo();
        const { indexTab, dataListCandidate } = this.state;
        if (data.selectedRowData.length > 0) {
            let oldData = dataListCandidate.map(rs => {
                return rs.CandidateID;
            });
            let newArr = [];
            data.selectedRowData.map(({ RecSourceID, CandidateID, ListMemberFollow }) => {
                if (oldData.indexOf(CandidateID) < 0) {
                    newArr.push({
                        RecSourceID,
                        CandidateID,
                        ListMemberFollow,
                    });
                }
                return {
                    RecSourceID,
                    CandidateID,
                    ListMemberFollow,
                };
            });

            const params = {
                FormID: indexTab === 0 ? "W25F2016" : indexTab === 2 ? "" : "W25F2017",
                RecInfoID: RecInfoID,
                Language: Config.language || "84",
                data: newArr,
                Mode: indexTab === 0 ? 0 : indexTab === 1 && 1,
            };
            if (newArr.length > 0) {
                this.props.W25F2015Actions.chooseCandidate(params, (error, data) => {
                    if (error) {
                        Config.popup.show('ERROR', error);
                        return false;
                    }
                    if (data.length > 0) {
                        if (indexTab === 0) {
                            this.setState(
                                {
                                    dataListCandidate: data,
                                    showW25F6000Popup: false,
                                },
                                () => {
                                    this.loadListCandidates(1);
                                }
                            );
                        }
                        if (indexTab === 1) {
                            this.setState(
                                {
                                    W25F2017dataListCandidate: data,
                                    showW25F6000Popup: false,
                                },
                                () => {
                                    this.loadListCandidates(0);
                                }
                            );
                        }
                    }
                });
            } else {
                this.setState(
                    {
                        showW25F6000Popup: false,
                    },
                    () => {
                        Config.popup.show("INFO", Config.lang("Khong_co_ung_vien_duoc_chon_moi"));
                    }
                );
            }
        }
    };

    openW25F2040Popup = dataTask => {
        this.setState({ CandidateID: dataTask?.CandidateID || "", DecisionID: dataTask?.DecisionID || "", showW25F2040Popup: true });
    };

    closePopup = (key, isSave = false) => {
        this.setState({ [key]: false });
        if (isSave && key === "showW25F2303Popup") {
            this.ref2018 && this.ref2018.loadGridW25F2018Left();
        }
        if (isSave && key === "showW25F1000Popup") {
            this.loadListCandidates(1, true);
        }
        if (key === "showW25F1000Popup") {
            this.setState({ CandidateID: "" });
        }
        if (key === "showW25F2040Popup") {
            this.setState({ showW25F2040Popup: false });
            this.loadListCandidates(1, true);
        }
    };

    addSchedule = () => {
        this.setState({ showW25F2303Popup: true });
    };
    onChosenW25F1000 = () => {};

    filterChange = (key, e) => {
        if(!key || !e) return null;
        const value = e?.value ?? e?.target?.value ?? "";
        if(key === "RangePicker") {
            const DateFrom = Array.isArray(value) ? value[0] : value;
            const DateTo = Array.isArray(value) ? value[1] : value;
            this.dataFilter['InterviewDateFrom'] = DateFrom;
            this.dataFilter['InterviewDateTo'] = DateTo;
        } else {
            this.dataFilter[key] = value;
        }
    };

    _onOpened = () => {
        this.loadCboFilterCandidate();
        this.loadCboInterview();
        this.loadCboStage();
    };

    loadGridCandicate = () => {
        this.setState({
            dataFilter: {...this.dataFilter}
        },()=>{
            if(this.ref2018) this.ref2018.loadListCandicate({}, this.dataFilter);
        });
    };

     renderFilter = () => {
         const {getCboFilterCandidate, getCboInterview, getCboStage} = this.props;
         const { loadingCboFilterCandidate,
             loadingCboInterview, loadingCboStage,
             dateRange, dataFilter  } = this.state;
        return (
            <DropdownBox
                bgColor
                openOnClickAt={"full"}
                placeholder={Config.lang("Tim_kiem_lich_phong_van")}
                viewType={"outlined"}
                inputStyle={{ border: "none" }}
                onOpened={this._onOpened}
                onClosed={() => {
                    this.dataFilter = { ...dataFilter };
                }}
                inputProps={{ readOnly: true }}
            >
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"CandidateID"}
                            displayExpr={"CandidateName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Ung_vien")}
                            defaultValue={dataFilter?.CandidateID}
                            loading={loadingCboFilterCandidate}
                            onChange={e => this.filterChange("CandidateID", e)}
                            dataSource={getCboFilterCandidate}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"CanConfirmStatusID"}
                            displayExpr={"CanConfirmStatusName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Ung_vien_xac_nhan")}
                            defaultValue={dataFilter?.CanConfirmStatusID}
                            onChange={e => this.filterChange("CanConfirmStatusID", e)}
                            dataSource={this.canConfirmStatus}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"InterviewStatusID"}
                            displayExpr={"InterviewStatusName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Trang_thai")}
                            defaultValue={dataFilter?.InterviewStatusID}
                            onChange={e => this.filterChange("InterviewStatusID", e)}
                            dataSource={this.interviewStatus}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"StageID"}
                            displayExpr={"StageName"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Giai_doan")}
                            defaultValue={dataFilter?.StageID}
                            loading={loadingCboStage}
                            onChange={e => this.filterChange("StageID", e)}
                            dataSource={getCboStage}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"interviewTypeID"}
                            displayExpr={Config.language === "84" ? "interviewTypeNameVI" : "interviewTypeNameEN"}
                            placeholder={Config.lang("Chon")}
                            label={Config.lang("Loai_phong_van")}
                            defaultValue={dataFilter?.InterviewTypeID}
                            onChange={e => this.filterChange("InterviewTypeID", e)}
                            dataSource={this.interviewType}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Dropdown
                            clearAble
                            allowSearch
                            style={{ margin: 0 }}
                            viewType={"outlined"}
                            valueExpr={"InterviewerID"}
                            displayExpr={"InterviewerName"}
                            placeholder={Config.lang("Chon")}
                            loading={loadingCboInterview}
                            label={Config.lang("Nguoi_phong_van")}
                            defaultValue={dataFilter?.InterviewerID}
                            onChange={e => this.filterChange("InterviewerID", e)}
                            dataSource={getCboInterview}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <DateRangePicker
                            controls
                            clearAble
                            defaultValue={dateRange}
                            style={{ marginBottom: 0 }}
                            viewType={"outlined"}
                            label={Config.lang("Thoi_gian_phong_van")}
                            onChange={e => this.filterChange("RangePicker", e)}
                            placeholder={"DD/MM/YY - DD/MM/YY"}
                            returnFormat={"YYYY-MM-DD"}
                        />
                    </Col>
                    <Col xs={12} className={"display_row align-center valign-middle"}>
                        <Button
                            size={"medium"}
                            color={"primary"}
                            viewType={"outlined"}
                            label={Config.lang("Tim_kiem")}
                            startIcon={"Search"}
                            onClick={this.loadGridCandicate}
                        />
                    </Col>
                </Row>
            </DropdownBox>
        );
    };


    render() {
        // if (!this.getInfo()) return null;
        const { RecInfoTitle, RecInfoID } = this.getInfo();
        const { classes } = this.props;
        const {
            indexTab,
            dataStage,
            dataListCandidate,
            showW25F6000Popup,
            loadStage,
            W25F2017dataListCandidate,
            disabledView,
            disabledAdd,
            showW25F2303Popup,
            dataQuickView,
            showW25F2022Popup,
            showW25F1000Popup,
            showW25F2040Popup,
            CandidateID,
            mode,
            DecisionID,
            dataFilter,
            requiredFields
        } = this.state;
        let checkTabName = indexTab !== 2 ? Config.lang('Sang_loc_ung_vien') : Config.lang("Them_lich_phong_van"),
            checkTabClick = indexTab !== 2 ? this.onFilter : this.addSchedule;
        return (
            <React.Fragment>
                <div style={{ marginBottom: -15 }} className={indexTab === 0 ? classes.transform : ""}>
                    {this.isRenderModalW25F6000 && (
                        <W25F6000
                            open={showW25F6000Popup}
                            dataSelected={this.dataTemp}
                            FormID={indexTab === 0 ? "W25F2016" : "W25F2017"}
                            onChosen={this.onChosen}
                            onClose={() => this.closePopup("showW25F6000Popup")}
                        />
                    )}
                    <W25F1000
                        open={showW25F1000Popup}
                        RecInfoID={RecInfoID}
                        CandidateID={CandidateID}
                        mode={mode}
                        FormID={"W25F2015"}
                        onClose={isSave => this.closePopup("showW25F1000Popup", isSave)}
                        requiredFields={requiredFields}
                    />
                    {showW25F2303Popup && (
                        <W25F2303
                            open={showW25F2303Popup}
                            RecInfoID={RecInfoID}
                            onChosen={() => this.ref2018 && this.ref2018.loadListCandicate()}
                            FormID={"W25F2018"}
                            onClose={isSaved => {
                                this.closePopup("showW25F2303Popup", isSaved);
                            }}
                        />
                    )}
                    <W25F2022
                        openPopup={showW25F2022Popup}
                        data={dataQuickView}
                        onClosePopup={() => {
                            this.closePopup("showW25F2022Popup");
                        }}
                    />
                    <W25F2040
                        open={showW25F2040Popup}
                        onClose={() => {
                            this.closePopup("showW25F2040Popup");
                        }}
                        RecInfoID={RecInfoID}
                        CandidateID={CandidateID}
                        TransID={DecisionID}
                        fullCandidateInfos={dataQuickView}
                    />
                    <ActionToolbar
                        title={RecInfoTitle}
                        onBack={this.onBack}
                        alignment={"space-between"}
                        allwaysTop={false}
                    >
                        <div className="display_row align-center flex-wrap" style={{ minWidth: 170 }}>
                            {this.showInfoEmployment()}
                            <Typography type={"h4"}>{Config.lang("Tuy_chon")}</Typography>
                            {this.showListAction()}
                        </div>
                        <div className={classes.buttonAction + " display_row align-end"} style={{ gap: spacing() }}>
                            <Button
                                label={Config.lang("Xem_nhanh")}
                                startIcon={"View"}
                                style={{ textTransform: "uppercase", minWidth: 185 }}
                                viewType={"outlined"}
                                disabled={disabledView || _.isEmpty(dataQuickView)}
                                onClick={this.dataQuickView}
                            />
                            <Button
                                label={checkTabName}
                                startIcon={indexTab === 2 ? "AddFilled" : "send"}
                                disabled={disabledAdd}
                                color={"primary"}
                                viewType={"filled"}
                                style={{ textTransform: "uppercase", minWidth: 185 }}
                                onClick={checkTabClick}
                            />
                            {indexTab === 1 && (
                                <Button
                                    label={Config.lang("Them_moi_ung_vien")}
                                    startIcon={"AddFilled"}
                                    viewType={"filled"}
                                    color={"primary"}
                                    disabled={disabledAdd}
                                    style={{ textTransform: "uppercase", minWidth: 185 }}
                                    onClick={this.onAdd}
                                />
                            )}
                        </div>
                    </ActionToolbar>
                    <TabContainer tabIndex={indexTab}>
                        <Divider />
                        <TabHeader className={"flex-wrap"}>
                            <TabItem
                                index={0}
                                onClick={this.handleChanged}
                                label={Config.lang("Luong_cong_viec")}
                            />
                            <TabItem index={1} onClick={this.handleChanged} label={Config.lang("Ung_vien")} />
                            <TabItem index={2} onClick={this.handleChanged} label={Config.lang("Phong_van")} />
                            {indexTab === 2 && <div className={classes.customFilter}>{this.renderFilter()}</div>}
                        </TabHeader>
                        <Divider />
                        <TabPanel index={0} className={"pd0"}>
                            {dataStage.length > 0 && dataListCandidate.length > 0 && (
                                <W25F2015WorkFlow
                                    indexTab={indexTab}
                                    dataStage={dataStage}
                                    updateStage={column => {
                                        this.dataTemp = column;
                                    }}
                                    dataTemp={this.dataTemp}
                                    dataListCandidate={dataListCandidate}
                                    openW25F2040Popup={this.openW25F2040Popup}
                                    loadListCandidate={this.loadListCandidates}
                                />
                            )}
                            {!dataStage.length > 0 && (
                                <div ref={evt => (this.workflow = evt)} className={classes.loading}>
                                    {[1, 2, 3, 4, 5].map((item, index) => {
                                        return (
                                            <div
                                                className="list-column"
                                                key={index}
                                                style={{ minWidth: `calc(100% / 5)` }}
                                                ref="list-column"
                                            >
                                                <div
                                                    className="list-header"
                                                    style={{
                                                        height: 40,
                                                    }}
                                                />
                                                <div style={{ backgroundColor: "#fff", paddingLeft: 5, paddingTop: 5 }}>
                                                    <div
                                                        className="display_col overflow-hover"
                                                        style={{
                                                            height: "calc(100vh - 214px)",
                                                            overflow: "hidden",
                                                            backgroundColor: "#F5F6F6",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </TabPanel>
                        <TabPanel index={1} className={"pd0"}>
                            <W25F2017Candidate
                                dataStage={dataStage}
                                updateStage={this.dataTemp}
                                dataListCandidate={W25F2017dataListCandidate}
                                loadListCandidate={this.loadListCandidates}
                                loadingListCandidate={this.state.loadListCandidate}
                                RecInfoTitle={RecInfoTitle}
                                onEdit={this.onEdit}
                            />
                        </TabPanel>
                        <TabPanel index={2} lazyLoading className={"pd0"}>
                            {RecInfoID && (
                                <W25F2018
                                    dataFilter={dataFilter}
                                    RecInfoTitle={RecInfoTitle}
                                    ref={ref => (this.ref2018 = ref)}
                                    RecInfoID={RecInfoID}
                                />
                            )}
                        </TabPanel>
                    </TabContainer>
                    <Loading visible={this.workflow && loadStage} />
                </div>
            </React.Fragment>
        );
    }
}

export default compose(
    connect((state) => ({
        getCboFilterCandidate: state.W25F2018.getCboFilterCandidate,
        getCboInterview: state.W25F2018.getCboInterview,
        getCboStage: state.W25F2018.getCboStage,
    }), dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch),
        w25F2018Actions: bindActionCreators(W25F2018Actions, dispatch),
    })),
    withStyles(styles, { withTheme: true })
)(W25F2015);
