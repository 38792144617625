/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup, Col, Row, Image}  from "react-bootstrap";
import Config                        from "../../../../../config";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../../redux/W2X/W25F2011/W25F2011_actions";
import * as generalActions           from "../../../../../redux/general/general_actions";
import withStyles                    from "@material-ui/core/styles/withStyles";
import {FormControl, FormHelperText} from "@material-ui/core";
import Dropzone                      from "react-dropzone";
import InlineSVG                     from "react-inlinesvg";
import moment                        from "../../../../common/attachments/attachments";
import Typography                    from "@material-ui/core/Typography";
import {Editor}                      from "@tinymce/tinymce-react";
import CDN                           from "../../../../CDN";

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        position: 'relative',
        minHeight: 165,
        border: '1px solid #687799',
        paddingBottom: 20
    },
    border: {
        borderImageWidth: 'initial',
        borderImageOutset: 'initial',
        borderImageSource: 'url(https://aui-cdn.atlassian.com/media/border-icon.png)',
        borderImageSlice: 2,
        borderImageRepeat: 'round',
        height: '100%',
        width: '100%',
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: 'rgb(207, 212, 219)',
        borderRadius: 3,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& .text-muted': {
            color: 'rgb(167, 167, 167)',
        }
    },
    dropzone: {
        position: 'absolute',
        // top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 98,
        cursor: 'pointer',
        border: "1px solid #EBECF2"
    },
    dropzoneActive: {
        backgroundColor: "#eaeaeabf",
        zIndex: 2000,
    },
    bgNone: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "#EBECF2"
    },
    iconAdd: {
        marginBottom: 10,
    },
    heading: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    borderRed: {
        border: "1px solid red"
    }
};
const _sizeLimit = 20000;
class W25F2011Expand2 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDragOver: false,
            dataFiles: props && props.dataForm && props.dataForm.ImageURL ? [props.dataForm.ImageURL] : [],
            _files: [],
        };
        this.filterCboProjects = {

        };
        // this.refEditor = null;
        // this.isSetContent = false;
    }

    onDragOver = () => {
        this.setState({ isDragOver: true });
    };

    checkFileType = (filename) => {
        const extension = filename.substr(filename.lastIndexOf('.')).toLowerCase();
        const allowedExtensions = ['.jpg', '.jpeg', '.png'];
        if (extension.length > 0) {
            if (allowedExtensions.indexOf(extension) === -1) {
                return false;
            }
        }
        return true;
    };

    onDrop = async (files) => {
        const { maxSize, maxLength, chooseWithoutSave, isAttachInfo } = this.props;
        const oldFiles = this.props.files;
        let messageSize = [];
        let messageName = [];
        const sizeLimit = maxSize ? maxSize : _sizeLimit;
        let _files = this.state._files;
        let _tmpFiles = [];
        if (!Config.profile.UserID) {
            Config.popup.show("INFO", Config.lang("DHR_Khong_the_dinh_kem"));
            return;
        }
        for (let i = 0; i < files.length; i++) {
            if (_files.length + (oldFiles ? oldFiles.length : 0) >= maxLength) {
                Config.popup.show("INFO", Config.lang("DHR_Vuot_qua_so_luong_tap_tin_cho_phep") + "<br/>" + Config.lang("DHR_So_luong_cho_phep") + ": " + maxLength);
                break;
            }
            if ((files[i].size / 1024) > Number(sizeLimit)) {
                messageSize.push(files[i].name);
            } else if (this.checkFileType(files[i].name) === false) {
                messageName.push(files[i].name)
            } else {
                const dtime = new Date().getTime() + i;
                const fileInfo = isAttachInfo ? { CreateUserID: Config.profile.UserID, CreateDate: moment().format('YYYY-MM-DD kk:mm:ss')} : {}
                const file = {
                    isNew: true,
                    key: dtime,
                    fileName: files[i].name,
                    fileSize: files[i].size,
                    fileExt: files[i].name.split('.').pop(),
                    url: files[i].type.indexOf('image') > -1 ? URL.createObjectURL(files[i]) : null,
                    _file: files[i],
                    ...fileInfo
                };
                _tmpFiles.push(file);
                _files.unshift(file);
            }
        }
        let message = "";
        if (messageSize.length > 0) {
            message += Config.lang("DHR_Tap_tin") + ": " + messageSize.join(",") + "<br>" + Config.lang("Dung_luong_File_khong_duoc_lon_hon") + " " + sizeLimit + ' KB <br>';
        }
        if (messageName.length > 0) {
            message += Config.lang("DHR_Tap_tin") + ": " + messageName.join(",") + "<br>" + Config.lang("Dinh_dang_file_khong_hop_le");
        }
        if (message) {
            Config.popup.show("INFO", message);
        }
        this.setState({
            _files: _files,
        }, () => {
            //auto upload file when select files..
            if (_tmpFiles.length > 0) {
                if (!chooseWithoutSave) {
                    const limitUploadedFile = 1; //allow upload once 5 files
                    this.countFileUploading = 0;
                    _tmpFiles.forEach((_f) => {
                        if (limitUploadedFile && this.countFileUploading >= limitUploadedFile) {
                            this.timerUpload[_f.key] = setInterval(() => {
                                if (this.countFileUploading < limitUploadedFile) {
                                    this.countFileUploading++;
                                    this.__uploadFiles(_f, _f.fileName);
                                    clearInterval(this.timerUpload[_f.key]);
                                }
                            }, 1000);
                        } else {
                            this.countFileUploading++;
                            this.__uploadFiles(_f, _f.fileName);
                        }
                    });
                }
            }
        });
    };

    __uploadFiles = (file, fileName) => {
        this._uploadFile([file._file], false, (err, data) => {
            this.countFileUploading--;
            if (err) return false;
            if (data && data.paths && data.paths.length > 0) {
                const path = data.paths[0];
                let _uploadFile = Object.assign(file, path);
                if (fileName) {
                    _uploadFile.fileName = fileName;
                }
                const {onChange} = this.props;
                if (onChange) onChange("ImageURL", {value: _uploadFile && _uploadFile.url ? _uploadFile.url : ""})
                this.setState({dataFiles: [_uploadFile]});
            }
        });
    };

    _uploadFile = (files, isAsync, cb) => {
        // return await FileUploadSync(files, mode, this.tokenCDN);
        if (isAsync) {
            return CDN.uploadFileSync(files);
        } else {
            return CDN.uploadFile(files, null, cb);
        }
    };

    handleChange = (key, e) => {
        if (!key) return false;
        const {onChange} = this.props;
        if (onChange) onChange(key, {value: e});
    };

    onValidate = () => {
        const {onValidate} = this.props;
        const ContentInfo = this.refEditor ? this.refEditor.getContent() : "";
        if (onValidate) onValidate("ContentInfo", {value: ContentInfo});
    };

    componentDidMount () {
        const ImageURL2 = this.props && this.props.dataForm && this.props.dataForm.ImageURL ? this.props.dataForm.ImageURL : "";
        if (ImageURL2) {
            this.setState({
                dataFiles: [{url: ImageURL2}]
            });
        }
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {dataFiles} = this.state;
        const Image = dataFiles && dataFiles.length > 0 ? dataFiles[0].url : "";
        if (Image !== this.props.dataForm.ImageURL) {
            const ImageURL2 = this.props && this.props.dataForm && this.props.dataForm.ImageURL ? this.props.dataForm.ImageURL : "";
            this.setState({
                dataFiles: [{url: ImageURL2}]
            });
        }
    }

    // setDataEditor = () => {
    //     const { dataForm } = this.props;
    //     if(this.refEditor && !this.isSetContent && this.refEditor?.getContent() !== dataForm?.ContentInfo) {
    //         this.refEditor.setContent(dataForm?.ContentInfo || "");
    //         this.isSetContent = true;
    //     }
    // };

    getContentEditor = () => {
        if(this.refEditor) {
            return this.refEditor.getContent();
        }
    };

    render() {
        const { classes, error, dataForm } = this.props;
        const { isDragOver, disabled, dataFiles} = this.state;
        const errorImage = error && error["ImageURL"] && dataFiles.length <= 0;
        const errorContent = error && error["ContentInfo"] && !(this.refEditor ? this.refEditor.getContent() : "");

        return (
            <div style={{width: "100%"}}>
                <Row>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <FormGroup className={"mgt15"}>
                            <fieldset>
                                <Typography style={{marginBottom: 10}} className={classes.heading}>{Config.lang("DHR_Anh_dai_dien")}&nbsp;<span style={{color: "red"}}>*</span></Typography>
                                <Typography style={{marginBottom: 10}} className={"text-muted"} variant={"body2"}>{Config.lang("DHR_Ty_le_cho_phep")}: 3:4</Typography>
                                <div style={{ width: '100%', height: 365, position: "relative" }}>
                                    <span className={"display_col align-center valign-middle"} style={{ width: '100%', height: '100%' }}>
                                        <Dropzone ref={ref => this.dropzoneRef = ref}
                                                  onDragOver={this.onDragOver}
                                                  style={{ ...(isDragOver ? { zIndex: 1000 } : {}) }}
                                                  disabled={disabled}
                                                  accept={"image/jpg, image/jpeg, image/png"}
                                                  onDrop={this.onDrop}>
                                            {({ getRootProps, getInputProps, isDragActive }) => (
                                                <>
                                                    <div {...getRootProps({
                                                        className: classes.dropzone + (isDragActive ? " " + classes.dropzoneActive : "") +
                                                                       (errorImage ? " " + classes.borderRed : "")
                                                    })}
                                                    >
                                                        <input {...getInputProps()} />
                                                    </div>
                                                    {dataFiles && dataFiles.length < 1 ?
                                                        (
                                                        <React.Fragment>
                                                            <div className={classes.bgNone} />
                                                            <div className={"display_col align-center valign-middle"} style={{zIndex: 1}}>
                                                                <InlineSVG src={require('../../../../../assets/images/general/no-image.svg')} className={classes.iconAdd} />
                                                                <span style={{ fontStyle: 'italic', fontSize: 12 }} >{Config.lang("DHR_Nhan_vao_de_tai_hinh_hoac_keo_tha_hinh_vao_day")}</span>
                                                            </div>
                                                        </React.Fragment>
                                                        ) : (
                                                            dataFiles && dataFiles.map((file, idx) => {
                                                                const img = file.url && file.url.indexOf("http") > -1 ? file.url : Config.getCDNPath() + file.url;
                                                                return <Image key={idx} src={img} style={{width: "100%", height: "100%", objectFit: "contain"}} />
                                                            })
                                                        )
                                                    }
                                                </>
                                            )}
                                        </Dropzone>
                                    </span>
                                </div>
                            </fieldset>
                        </FormGroup>
                        <FormGroup>
                            <fieldset>
                                <Typography style={{marginBottom: 10}} className={classes.heading}>
                                    {Config.lang("DHR_Noi_dung_tin_tuyen_dung")}&nbsp;<span style={{color: "red"}}>*</span>
                                </Typography>
                                <div>
                                    <FormControl variant={"standard"}
                                                 error={!!errorContent}
                                                 margin={"normal"}
                                                 className={errorContent ? classes.borderRed : ""}
                                                 fullWidth={true}>
                                            <Editor
                                                initialValue={dataForm?.ContentInfo || ""}
                                                init={{
                                                    height: 360,
                                                    plugins: "advlist lists link image",
                                                    toolbar:
                                                        "styleselect | bold italic forecolor | bullist numlist | link image| removeformat",
                                                    menubar: false,

                                                }}

                                                onInit={(e, editor) => {
                                                    this.refEditor = editor;
                                                    // setTimeout(() => {
                                                    //     this.setDataEditor();
                                                    // }, 500)

                                                }}
                                                onEditorChange={(e) => this.handleChange("ContentInfo", e)}
                                                onBlur={this.onValidate}
                                            />
                                        <FormHelperText>{error && errorContent && error["ContentInfo"]}</FormHelperText>
                                    </FormControl>
                                </div>
                            </fieldset>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

}

W25F2011Expand2.propTypes = {
    error: PropTypes.any,
    dataForm: PropTypes.object,
    onChange: PropTypes.func,
};

export default compose(connect((state) => ({
    getColumns: state.W25F2011.getColumns,
    getCboColumns: state.W25F2011.getCboColumns,
    getHourDecimal: state.W25F2011.getHourDecimal,
    getMaster: state.W25F2011.getMaster,
    getGridMaster: state.W25F2011.getGridMaster,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
}), null, {forwardRef: true}), withStyles(styles))(W25F2011Expand2);