import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W25F2012Actions from "../../../../redux/W2X/W25F2012/W25F2012_actions";
import Config from "../../../../config";
import _ from "lodash";
import Api from "../../../../services/api";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import PropTypes from "prop-types";
import W25F2040 from "../W25F2040/W25F2040";
import SendMailPop from "../W25F2015/utils/SendMailPop";
import {
    Row,
    Col,
    Button,
    ButtonIcon,
    Checkbox,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Rating,
    Tooltip,
    TextInput,
    FormGroup,
    Card,
    CardBody,
    LinearProgress,
    Avatar,
    Breadcrumb,
    Popover,
    Badge,
} from "diginet-core-ui/components";
import Icons from "diginet-core-ui/icons";
import { makeStyles, useTheme } from "diginet-core-ui/theme";
const { colors } = useTheme();

const useStyles = makeStyles(theme => ({
    boxShadowCard: {
        boxShadow: theme.boxShadows.small
    },
    cardColumnCenter: {
        [theme.breakpoints.down("xs")]: {
            alignItems: "center",
            padding: theme.spacing([4, 0, 0])
        }
    },
    boxShadow: {
        boxShadow: theme.boxShadows.large,
    },
    badge: {
        position: "relative",
        "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            borderRadius: 2,
            backgroundColor: theme.colors.text.sub,
            height: 1,
            top: "50%",
            bottom: 0,
            left: 0,
        },
        "&.active:before": {
            height: 3,
            top: "49%",
            backgroundColor: theme.colors.primary,
        }
    },
    expansionPanelDetails: {
        flex: 1,
        flexDirection: "column",
    },
    editingComment: {
        opacity: 1
    },
    tlContainer: {
        maxHeight: 320,
        overflowY: "auto",
        overflowX: "hidden",
        padding: theme.spacing([3, 1])
    },
    tl: {
        "& > :last-child": {
            "&:after": {
                content: "none"
            }
        }
    },
    dashedBox: {
        padding: theme.spacing([4, 0]),
        borderTopWidth: 3,
        borderTopColor: theme.colors.line.system,
        borderTop: "dashed"
    },
    timeLine: {
        height: 74,
        position: "relative",
        "&:after": {
            content: "''",
            position: "absolute",
            width: 2,
            borderRadius: 1,
            backgroundColor: theme.colors.text.sub,
            top: 0,
            bottom: 0,
            left: 0,
        }
    },
    containerTimeline: {
        position: "relative",
        left: 20,
        "&:after": {
            content: "''",
            position: "absolute",
            width: 8,
            height: 8,
            left: -22.6,
            backgroundColor: theme.colors.text.sub,
            top: 0,
            borderRadius: "50%",
            zIndex: 1
        }
    },
    buttonActionComment: {
        paddingLeft: theme.spacing(4),
        display: 'inline-block'
    },
    contentTimeline: {
        transform: "translateY(-10px)",
        "&:hover $hoverActionTimeline": {
            opacity: 1,
            transition: "0.6s"
        }
    },
    inputComment: {
        width: "96%",
    },
    hoverActionTimeline: {
        display: "flex",
        paddingLeft: theme.spacing(),
        transition: "0.6s",
        opacity: Config.isMobile ? 1 : 0,
        "&:hover": {
            opacity: 1,
        }
    },
    labelProgress: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(3)
    },
    avatarPoint: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        color: theme.colors.info,
        border: `2px solid ${theme.colors.info}`
    },
    typoH4: theme.typography.h4,
    candidateAvatar: {
        paddingRight: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
        },
    },
    colSlider: {
        width: "96%"
    },
    boxInfoUser: {
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    iconStatus: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: -15,
        top: 10,
        width: 34,
        height: 34,
        borderRadius: "24px 0 0 24px",
        color: "white",
        background: theme.colors.secondary
    },
    btnStage: {
        "& .DGN-UI-Button-Start-Icon": {
            "@media (max-width: 1350px)": {
                display: "none !important"
            },
            "@media (max-width: 992px)": {
                display: "inline !important",
            }
        }
    }
}));

const { spacing } = useTheme();
class W25F2012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: [],
            anchorEl: null,
            dataUserComment: [],
            dataUserEvaluation: [],
            dataUser: [],
            note: "",
            isReadOnly: this?.props?.data?.isReadOnly || false,
            messErrCandidateHired: "",
            isRecommend: false,
            isSaving: false,
            isLoadingAction: false,
            isLoadingCandidateInfo: false,
            isLoadingUserEvaluation: false,
            isLoadingOtherEvaluation: false,
            dataCandidateInfo: {},
            showW25F2040Popup: false,
            isOpenSendMailPop: false,
            currentStage: null,
        };
        this.params = {
            FormID: "W25F2012",
            Language: Config.language || 84,
            FieldName: "W25F2012",
        };
        this.oldDataUserComment = null;
        this.timerCandidateInfo = null;
        this.timerUserEvaluaton = null;
        this.timerOtherEvaluation = null;
    }

    clsx = (...spread) => _.join(spread, " ");

    getCandidateInfo = () => ({
        CandidateID: _.get(this.props, "data.CandidateID", ""),
        RecInfoID: _.get(this.props, "data.RecInfoID", ""),
        InterviewFileID: _.get(this.props, "data.InterviewFileID", ""),
    });

    getStageName = (stageID) => {
        const { loadStage } = this.props;
        if (!stageID) return "";
        const getIndexStage = _.findIndex(loadStage, o => o.StageID === stageID);
        return _.get(loadStage, `[${getIndexStage}]StageName`, "");
    };

    loadStage = () => {
        this.props.w25f2012Actions.loadStage(_.pick(this.getCandidateInfo(), ["RecInfoID"]), err => {
            if (err) Config.popup.show("INFO", err);
        });
    };

    loadCheckCandidateHired = async () => {
        const { RecInfoID, CandidateID } = this.getCandidateInfo();
        const params = {
            FieldName: "IsEmployee",
            Values: "",
            Key01ID: RecInfoID,
            Key02ID: CandidateID,
            Mode: 0,
            Language: this.params.Language
        }
        const res = await Api.put("/w25f2012/check", params);
        const resData = _.get(res, "data[0]", _.get(res, "data", {}));
        const resDataStatus = _.get(resData, "Status", 0);
        const resDataMessages = _.get(resData, "Message", "") || Config.lang("Loi_chua_xac_dinh");
        if (resDataStatus === 1) {
            this.setState({ isReadOnly: true, messErrCandidateHired: resDataMessages })
        }
    };

    loadInfoCandidate = () => {
        if (this.timerCandidateInfo) clearTimeout(this.timerCandidateInfo);
        this.props.w25f2012Actions.loadInfoCandidate({ ...this.params, ...this.getCandidateInfo() }, (err, data) => {
            this.timerCandidateInfo = setTimeout(() => {
                this.setState({ isLoadingCandidateInfo: false, dataCandidateInfo: (_.get(data, "[0]", {})) });
            }, 1000);
            if (err) {
                Config.popup.show("INFO", err);
                return false;
            }
            if (_.get(data, "[0].StageID", "") === "HIRED") this.loadCheckCandidateHired()
        });
    };

    loadUserEvaluation = () => {
        if (this.timerUserEvaluaton) clearTimeout(this.timerUserEvaluaton);
        this.props.w25f2012Actions.loadUserEvaluation({ ...this.params, ...this.getCandidateInfo() }, (err, data) => {
            this.timerUserEvaluaton = setTimeout(() => {
                this.setState({ isLoadingUserEvaluation: false })
            }, 1000);
            if (err) {
                Config.popup.show("INFO", err);
                return false;
            }
            if (data) {
                const dataUserComment = _.get(data, "[0]Comment", []);
                const dataUserEvaluation = _.get(data, "[0]Evaluation", []);
                const note = _.get(data, "[0]PrivateNote", "");
                const isRecommend = _.get(data, "[0]IsRecommend", 0) === 1;
                this.setState({ dataUserComment, dataUserEvaluation, note, isRecommend }, () => {
                    this.oldDataUserComment = _.get(dataUserComment, "Comment", []);
                });
            }
        });
    };

    loadOtherEvaluation = () => {
        if (this.timerOtherEvaluation) clearTimeout(this.timerOtherEvaluation);
        this.props.w25f2012Actions.loadOtherComment({ ...this.params, ...this.getCandidateInfo() }, (err) => {
            if (err) Config.popup.show("INFO", err);
            this.timerOtherEvaluation = setTimeout(() => {
                this.setState({ isLoadingOtherEvaluation: false });
            }, 1000)
        });
    };

    handleActionComment = (i = 0, typeEdit = "", value = "") => {
        const { dataUserComment } = this.state;
        switch (typeEdit) {
            case "isEditing":
                this.oldDataUserComment = dataUserComment;
                this.setState({
                    dataUserComment:
                        [
                            ..._.map(_.slice(dataUserComment, 0, i), item => ({
                                ...item,
                                isEditing: false
                            })),
                            {
                                ...dataUserComment[i],
                                [typeEdit]: value,
                            },
                            ..._.map(_.slice(dataUserComment, i + 1), item => ({
                                ...item,
                                isEditing: false
                            }))
                        ]
                });
                break;
            case "Content":
                this.setState({
                    dataUserComment: [
                        ..._.slice(dataUserComment, 0, i),
                        {
                            ...dataUserComment[i],
                            [typeEdit]: value,
                        },
                        ..._.slice(dataUserComment, i + 1)
                    ]
                });
                break;
            case "add":
                const dataAdding = _.concat(_.map(dataUserComment, item => ({ ...item, isEditing: false })), {
                    Content: "",
                    CreateDate: Config.convertDate(new Date(), null, "DD/MM/YYYY, kk:mm:ss", true),
                    isEditing: true
                });
                this.oldDataUserComment = dataAdding;
                this.setState({
                    dataUserComment: dataAdding
                });
                break;
            case "remove":
                const dataRemoving = _.remove(dataUserComment, (_, idx) => idx !== i);
                this.oldDataUserComment = dataRemoving;
                this.setState({
                    dataUserComment: dataRemoving
                });
                break;
            case "redo":
                this.setState({
                    dataUserComment: [
                        ..._.slice(dataUserComment, 0, i),
                        {
                            ...dataUserComment[i],
                            Content: this.oldDataUserComment[i].Content
                        },
                        ..._.slice(dataUserComment, i + 1)
                    ]
                });
                break;
            default:
                return;
        }
    };

    handleMeanEpoint = (data) => {
        const sumEpoint = _.sumBy(data, (exp1) => _.divide(
            _.multiply(
                Number(_.get(exp1, "EPoint", 0)),
                Number(_.get(exp1, "Proportion", 0))
            ),
            _.sumBy(data, (exp2) => Number(_.get(exp2, "Proportion", 0)))
        )
        );
        return Math.round(sumEpoint * 100) / 100;
    }

    onChangeNote = e => {
        this.setState({ note: _.get(e, "target.value", "") });
    };

    onChangeEvaluation = (i, value, type = "EPoint") => {
        const { dataUserEvaluation } = this.state;
        this.setState({
            dataUserEvaluation: [
                ..._.slice(dataUserEvaluation, 0, i),
                {
                    ...dataUserEvaluation[i],
                    [type]: value
                },
                ..._.slice(dataUserEvaluation, i + 1),
            ]
        })

    };

    onChangeIsRecommend = () => {
        const { isRecommend } = this.state;
        this.setState({ isRecommend: !isRecommend })
    }

    onOpenToggleMenuButton = e => {
        this.setState({ anchorEl: e.currentTarget });
    };

    onCloseToggleMenuButton = (stageID = false) => {
        if (_.isString(stageID)) {
            this.onClickAction(stageID)
        }
        this.setState({ anchorEl: null });
    };


    onStore = async () => {
        const { dataUserComment, dataUserEvaluation, isRecommend, note } = this.state;
        const params = {
            PrivateNote: note,
            IsRecommend: isRecommend ? 1 : 0,
            data: JSON.stringify(dataUserEvaluation),
            comment: JSON.stringify(_.map(dataUserComment, item => _.omit(item, ['isEditing']))),
            ...this.params,
            ..._.pick(this.getCandidateInfo(), ["RecInfoID", "CandidateID", "InterviewFileID"]),
        }
        this.setState({ isSaving: true });
        const res = await Api.put("/w25f2012/save", params);
        this.setState({ isSaving: false });
        const messageErr = _.get(res, "message", "");
        const status = !_.get(res, "data.Status", 0);
        if (messageErr) {
            Config.popup.show("INFO", messageErr)
            return false;
        }
        if (status) {
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            this.setState({ isLoadingUserEvaluation: true, isLoadingCandidateInfo: true }, () => {
                this.loadUserEvaluation();
                this.loadInfoCandidate();
            })
            return true;
        }
        Config.popup.show("INFO", _.get(res, "data.Message", Config.lang("Loi_chua_xac_dinh")));
    }

    onClickAction = (StageID = false) => {
        const { loadInfoCandidate, loadStage } = this.props;
        const oldCandidateInfo = this.state.dataCandidateInfo;
        if (!StageID || (oldCandidateInfo?.IsApprovalOffer === 1 && oldCandidateInfo?.StageID === "HIRED")) return false;
        const style = this.getStageName(StageID) === "Rejected" ? `color:${colors.danger}` : `color:${colors.success}`;
        Config.popup.show("YES_NO", `${Config.lang("Ban_muon")} <b style=${style}>${this.getStageName(StageID)}</b> ${Config.lang("ung_vien_nay")}`, () => {
            (async () => {
                this.setState({ isLoadingAction: true });

                const currentStageID = loadInfoCandidate?.[0]?.StageID ?? "";
                const currentStage = loadStage?.find(i => i.StageID === currentStageID) ?? {};
                const newStage = loadStage?.find(i => i.StageID === StageID) ?? {};

                const IsApprovalOffer = loadInfoCandidate?.[0]?.IsApprovalOffer ?? 0;
                const IsAutoSendmail = newStage?.IsAutoSendmail ?? 0;
                const TemplateMailID = newStage?.EmailTemplate ?? "";
                const res = await Api.put("/w25f2012/load-options", {
                    ...this.params,
                    ..._.omit(this.getCandidateInfo(), ["InterviewFileID"]),
                    StageID,
                    StageID_new: StageID ?? "",
                    StageID_old: currentStageID ?? "",
                    IsApprovalOffer,
                    IsAutoSendmail,
                    TemplateMailID,
                });
                const resMessgage = res?.message || "";
                const resDataMessage = res?.data?.Message || "";
                const resDataStatus = res?.data?.Status;
                const currentStageOrder = currentStage?.StageOrderNo ?? null;
                const newStageOrder = newStage?.StageOrderNo ?? null;
                this.setState({ isLoadingAction: false });
                if (resMessgage || (!resDataStatus && oldCandidateInfo?.IsApprovalOffer === 1)) {
                    Config.popup.show("INFO", resMessgage || resDataMessage || Config.lang("Loi_chua_xac_dinh"));
                } else if (oldCandidateInfo?.IsApprovalOffer === 1) {
                    if (StageID === "HIRED") {
                        this.setState({ showW25F2040Popup: true });
                    } else if (currentStageOrder && newStageOrder && currentStageOrder > newStageOrder && resDataStatus === 1) {
                        Config.popup.show("YES_NO", Config.lang("Thao_tac_nay_dong_thoi_se_xoa_mat_phieu_de_xuat_Offer_cua_ung_vien._Ban_co_muon_tiep_tuc?"), async () => {
                            const res = await Api.put("/w25f2012/load-options-confirm", {
                                ...this.params,
                                ..._.omit(this.getCandidateInfo(), ["InterviewFileID"]),
                                StageID,
                                StageID_new: StageID ?? "",
                                StageID_old: currentStageID ?? "",
                                IsApprovalOffer,
                                IsAutoSendmail,
                                TemplateMailID,
                            });
                            const resMessgage = res?.message || "";
                            if (resMessgage) {
                                Config.popup.show("INFO", resMessgage);
                            } else {
                                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                                this.setState({ isLoadingCandidateInfo: true }, () => {
                                    this.loadInfoCandidate();
                                });
                            }
                        }, null);
                    } else {
                        if (resDataStatus === 2) {
                            const res = await Api.put("/w25f2012/load-options-confirm", {
                                ...this.params,
                                ..._.omit(this.getCandidateInfo(), ["InterviewFileID"]),
                                StageID,
                                StageID_new: StageID ?? "",
                                StageID_old: currentStageID ?? "",
                                IsApprovalOffer,
                                IsAutoSendmail,
                                TemplateMailID,
                            });
                            const resMessgage = res?.message || "";
                            if (resMessgage) {
                                Config.popup.show("INFO", resMessgage);
                            }
                        }
                        if (currentStageOrder && newStageOrder && newStageOrder > currentStageOrder && IsAutoSendmail) {
                            this.handleOpenPopup("sendMail", true, {
                                ...(loadInfoCandidate?.[0] ?? {}),
                                newStageID: StageID
                            });
                        } else {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            this.setState({ isLoadingCandidateInfo: true }, () => {
                                this.loadInfoCandidate();
                            });
                        }
                    }
                } else {
                    if (currentStageOrder && newStageOrder && newStageOrder > currentStageOrder && IsAutoSendmail) {
                        this.handleOpenPopup("sendMail", true, {
                            ...(loadInfoCandidate?.[0] ?? {}),
                            newStageID: StageID
                        });
                    } else {
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                        this.setState({ isLoadingCandidateInfo: true }, () => {
                            this.loadInfoCandidate();
                        });
                    }
                }
            })();
        });
    }

    renderCandidateProfile = () => {
        const classes = useStyles();
        const { loadStage, loadInfoCandidate, loadOtherEvaluation } = this.props;
        const { dataUserEvaluation, isRecommend, isReadOnly, messErrCandidateHired } = this.state;
        const candidateName = _.get(loadInfoCandidate, "[0]CandidateName", _.get(this.props, "data.CandidateName", ""));
        const candidatePictureURL = _.get(loadInfoCandidate, "[0]CandidatePictureURL", _.get(this.props, "data.CandidatePictureURL", ""));
        const urlPicture = candidatePictureURL ?
            (candidatePictureURL.indexOf("http") < 0
                ? Config.getCDNPath() + candidatePictureURL
                : candidatePictureURL)
            : ""
        const stageID = _.get(loadInfoCandidate, "[0]StageID", _.get(this.props, "data.StageID", ""));
        const arrEvaluation = [...dataUserEvaluation, ..._.get(loadOtherEvaluation, "[0]Evaluation", [])];
        const meanEpointCandidate = this.handleMeanEpoint(arrEvaluation);
        const numberRecommend = _.filter(loadOtherEvaluation, ["IsRecommend", 1]).length + (isRecommend ? 1 : 0);
        const stageNotRejected = _.filter(loadStage, item => item.StageID !== "REJECTED")
        const titleTooltip = <Breadcrumb separator={<Icons name={"ArrowRight"} color={"secondary"} />}>
            {
                _.map(stageNotRejected, (item, i) => (
                    <Typography key={item?.StageID} type={item.StageID === stageID ? 'h3' : 'p1'} color={'white'}>
                        {item.StageName}
                    </Typography>
                ))
            }
        </Breadcrumb>
        const stageWithValues = _.map(stageNotRejected, (item, i) => ({
            ...item,
            value: (i * 100) / (_.size(stageNotRejected) - 1)
        }));
        const badgeWidthPercentage = 100/(stageWithValues.length-1);
        const stageCurrent = _.find(stageWithValues, o => o.StageID === stageID);
        const stageName = _.get(stageCurrent, "StageName", _.get(loadInfoCandidate, "[0]StageName", _.get(this.props, "data.StageName", "")))
        const isMoved = !!_.get(loadInfoCandidate, "[0]IsMoved", 0);
        const isEmployee = !!_.get(loadInfoCandidate, "[0]IsEmployee", 0);
        const titleTooltipIsMoved = !!isReadOnly ? messErrCandidateHired : Config.lang("Da_chuyen1");
        const titleTooltipIsEmployee = Config.lang("Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay")
        return (
            <div style={{ position: "relative" }} className="display_row align-center mgb4x">
                <div className={classes.candidateAvatar}>
                    <Avatar
                        className={classes.boxShadow}
                        src={urlPicture}
                        width={120}
                        height={120}
                    />
                </div>
                <div className="display_col full_w">
                    <div className={`display_col align-left ${classes.cardColumnCenter}`}>
                        <Typography
                            type={"t3"}
                            color={"primary"}
                            className="full_w mgb2x"
                            format={['uppercase']}
                        >
                            {candidateName}
                        </Typography>
                        <Typography
                            type={"p1"}
                            color={"secondary"}
                            className="full_w"
                            format={['uppercase']}
                        >
                            {stageName}
                        </Typography>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <div className={classes.colSlider}>
                                <Tooltip tooltipMaxWidth={300} arrow title={titleTooltip}>
                                    <div>
                                        {stageID !== "REJECTED" && (
                                            <div className={`jus-between`}>
                                                {stageWithValues.map((marks, i) => {
                                                    return (
                                                        <Badge
                                                            style={{
                                                                width: `${
                                                                    i === stageWithValues.length - 1
                                                                        ? 0
                                                                        : badgeWidthPercentage
                                                                }%`,
                                                                justifyContent: "flex-start",
                                                            }}
                                                            className={`${
                                                                i === stageWithValues.length - 1 ? "" : classes.badge
                                                            } ${
                                                                _.get(stageCurrent, "value", 0) / 25 >= i + 1
                                                                    ? "active"
                                                                    : ""
                                                            }`}
                                                            size="giant"
                                                            color={`${
                                                                _.get(stageCurrent, "value", 0) / 25 >= i
                                                                    ? "primary"
                                                                    : "disabled"
                                                            }`}
                                                        />
                                                    );
                                                })}
                                            </div>)}
                                    </div>
                                </Tooltip>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <Rating
                                half
                                readOnly
                                height={24}
                                width={24}
                                color={"warning"}
                                value={_.toNumber(meanEpointCandidate)}
                            />
                        </Col>
                        <Col xs={3}>
                            <div
                                className="jus-end align-center">
                                <Typography
                                    type={"h3"}
                                    color={"info"}
                                >
                                    {numberRecommend}
                                </Typography>
                                <Icons
                                    height={30}
                                    width={30}
                                    name={"PersonAccept"} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Tooltip
                        title={isMoved ? titleTooltipIsMoved : isEmployee ? titleTooltipIsEmployee : ""}
                        arrow>
                        <div className={isMoved || isEmployee ? classes.iconStatus : ""}>
                            {isMoved ? <Icons name={"ReplaceAllRight"} color={"white"} width={30} /> : isEmployee ?
                                <Icons height={30} width={30} name={"Info"} /> : ""}
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }

    renderUserEvaluation = () => {
        const classes = useStyles();
        const { loadUserEvaluation } = this.props;
        const { dataUserComment, dataUserEvaluation, note, isRecommend, isSaving } = this.state;
        const meanEpointUser = this.handleMeanEpoint(dataUserEvaluation);
        const getUserEvaluation = _.get(loadUserEvaluation, "[0]", loadUserEvaluation);
        return (
            <div className={classes.expansionPanelDetails}>
                <div className="display_row">
                    <UserImage
                        className="mgr2x mgb4x"
                        keyExpr={"UserID"}
                        valueExpr={"UserID"}
                        allowHover={true}
                        data={getUserEvaluation}
                        width={48}
                        height={48}
                    />
                    <div className="display_col full_w">
                        <UserName allowHover={false} keyExpr={"UserID"} valueExpr={"UserID"}
                            data={getUserEvaluation}
                        />
                        <div className="display_row align-center">
                            <>
                                <Icons height={16} width={16} name={"Calendar"} className="mgr1x" />
                                <Typography
                                    type={"p1"}
                                    color={"secondary"}
                                >
                                    {Config.convertDate(_.get(getUserEvaluation, "ECreateDate", new Date()), null, "DD/MM/YYYY, kk:mm:ss", true)}
                                </Typography>
                            </>
                        </div>
                    </div>
                </div>
                <Typography type={'p2'} color={'secondary'} className="mgb3x">{Config.lang("Nhan_xet_ung_vien")}</Typography>
                <TextInput
                    multiline
                    rows={8}
                    viewType={"outlined"}
                    value={note}
                    disabled={isSaving}
                    onChange={this.onChangeNote}
                />
                <div className={classes.tlContainer}>
                    <div className={classes.tl}>
                        {_.map(dataUserComment, (item, i) => (
                            <div key={item?.CreateDate} className={classes.timeLine}>
                                <div className={classes.containerTimeline}>
                                    <div className={`${classes.contentTimeline} display_row align-center`}>
                                        <Typography
                                            type={"p2"}
                                            color={"secondary"}
                                        > {item?.CreateDate}
                                        </Typography>
                                        <div
                                            className={this.clsx(
                                                classes.buttonActionComment,
                                                item.isEditing
                                                    ? classes.editingComment
                                                    : classes.hoverActionTimeline
                                            )}
                                        >
                                            <ButtonIcon
                                                viewType={"ghost"}
                                                name={"Delete"}
                                                onClick={() => {
                                                    Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
                                                        this.handleActionComment(i, "remove")
                                                    })
                                                }}
                                            />
                                            {item.isEditing ? (
                                                <>
                                                    <ButtonIcon
                                                        disabled={isSaving}
                                                        viewType={"ghost"}
                                                        name={"Approval"}
                                                        onClick={() =>
                                                            this.handleActionComment(i, "isEditing", false)
                                                        }
                                                    />
                                                    <ButtonIcon
                                                        disabled={item.Content === _.get(this.oldDataUserComment, `[${i}].Content`, "") || isSaving}
                                                        viewType={"ghost"}
                                                        name={"Redo"}
                                                        onClick={() =>
                                                            this.handleActionComment(i, "redo")
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <ButtonIcon
                                                    disabled={isSaving}
                                                    viewType={"ghost"}
                                                    name={"Edit"}
                                                    onClick={() =>
                                                        this.handleActionComment(i, "isEditing", true)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <TextInput
                                        autoFocus={item.isEditing}
                                        disabled={!item.isEditing || isSaving}
                                        className={`mgb0x ${classes.inputComment}`}
                                        value={item.Content}
                                        multiline
                                        viewType={"outlined"}
                                        onChange={e =>
                                            this.handleActionComment(
                                                i,
                                                "Content",
                                                _.get(e, "target.value", "")
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="display_row align-center full_w valign-middle"
                >
                    <ButtonIcon
                        circular
                        name={"Add"}
                        viewType={"text"}
                        onClick={() => this.handleActionComment(_, "add")}
                    />
                </div>
                <div mb={2} className={classes.dashedBox}>
                    <div >
                        <Typography type={"h3"} color={"secondary"}>
                            {Config.lang("Cham_diem_theo_tieu_chi")}
                        </Typography>
                    </div>
                    {_.map(dataUserEvaluation, (item, i) => (
                        <div key={dataUserEvaluation?.EvaluationElementID}>
                            <div className="jus-between align-center mgb2x">
                                <Typography
                                    type={"p1"}
                                    color={"secondary"}
                                >
                                    {`• ${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", 1) || 1})`}
                                </Typography>
                                <Tooltip arrow title={`${Number(_.get(item, "EPoint", ""))} / 5`}>
                                    <Rating
                                        half
                                        color={"warning"}
                                        name={`rating -${i}`}
                                        disabled={isSaving}
                                        height={24}
                                        width={24}
                                        value={Number(_.get(item, "EPoint", 0))}
                                        onRating={(_, newValue) => {
                                            this.onChangeEvaluation(i, newValue)
                                        }}
                                    />
                                </Tooltip>
                            </div>
                            <TextInput
                                fullWidth
                                viewType={"outlined"}
                                value={_.get(item, "Notes", "") || ""}
                                disabled={isSaving}
                                onChange={e => this.onChangeEvaluation(i, _.get(e, "target.value", ""), "Notes")}
                            />
                        </div>
                    ))}
                </div>
                <div mb={2} className={classes.dashedBox}>
                    <Typography
                        type={"p1"}
                        color={"secondary"}
                        className={Config.isMobile ? "display_row valign-middle" : "jus-between"}
                    >
                        {Config.lang("Diem_trung_binh")}
                    </Typography>
                    <div
                        className={`display_col ${Config.isMobile ? "align-center" : "align-right"}`}
                    >
                        <Rating
                            half
                            readOnly
                            height={32}
                            width={32}
                            color={"warning"}
                            value={_.toNumber(meanEpointUser)}
                            className="mgb0x"
                        />
                        <div className="display_row align-center">
                            <Typography
                                type={"h3"}
                                color={"primary"}
                            >
                                {meanEpointUser}
                            </Typography>
                            /
                            <Typography
                                type={"p1"}
                                color={"secondary"}
                            >
                                5.0
                            </Typography>
                        </div>
                    </div>
                </div>
                <div mb={2} className={classes.dashedBox}>
                    <div>
                        <Typography type={"h3"} color={"secondary"}> {Config.lang("De_nghi_tuyen")}</Typography>
                        <Checkbox
                            className="mgv3x"
                            label={Config.lang("De_nghi_tuyen_ung_vien_nay")}
                            checked={isRecommend}
                            onChange={this.onChangeIsRecommend}
                            disabled={isSaving}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderOtherEvaluation = (data = []) => {
        const classes = useStyles();
        const { loadOtherEvaluation } = this.props;
        return (_.map(data,
            (itemUser, i) => {
                const arrOtherEvaluation = _.get(itemUser, "Evaluation", []);
                const arrOtherComment = _.get(itemUser, "Comment", []);
                const meanEpointOther = this.handleMeanEpoint(arrOtherEvaluation)
                return (
                    <FormGroup key={i}>
                        <div className="display_col full_w">
                            <div
                                className={`${classes.boxInfoUser} jus-between align-center`}
                            >
                                <div
                                    className="display_row valign-middle align-center"
                                >
                                    <UserImage keyExpr={"UserID"} valueExpr={"UserID"} allowHover={true}
                                        data={itemUser}
                                        className="mgr2x"
                                        width={48}
                                        height={48}
                                    />
                                    <div className="display_col">
                                        <UserName keyExpr={"UserID"} valueExpr={"UserID"} data={itemUser}
                                            allowHover={false} />
                                        <div className="display_row align-center">
                                            <Icons height={16} width={16} name={"Calendar"} className="mgr0x" />
                                            <Typography
                                                type={"p1"}
                                                color={"secondary"}
                                            >
                                                {Config.convertDate(_.get(itemUser, "ECreateDate", new Date()), null, "DD/MM/YYYY, kk:mm:ss", true)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className="display_row align-center">
                                    {_.get(itemUser, "IsRecommend", 0) ? <Icons
                                        height={32}
                                        width={32}
                                        name={"PersonAccept"}
                                        className="mgr3x"
                                    /> : ""}
                                    <div className={`display_row align-center valign-middle ${classes.avatarPoint} ${classes.typoH4}`}>{Number(meanEpointOther)}</div> :
                                </div>
                            </div>
                            <div className={classes.dashedBox}>
                                {_.map(arrOtherEvaluation, (item, i) => (
                                    <div key={i} >
                                        <div className="jus-between align-center">
                                            <Typography
                                                type={"p1"}
                                                color={"secondary"}
                                            >
                                                {`• ${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", 1) || 1})`}
                                            </Typography>
                                            <Tooltip arrow title={`${Number(_.get(item, "EPoint", ""))} / 5`}>
                                                <Rating
                                                    half
                                                    color="warning"
                                                    readOnly
                                                    height={24}
                                                    width={24}
                                                    name={`rating -${i}`}
                                                    value={Number(_.get(item, "EPoint", ""))}
                                                />
                                            </Tooltip>
                                        </div>
                                        <Typography style={{ minHeight: 20 }} type={'h4'}>{_.get(item, "Notes", "") || ""}</Typography>
                                    </div>
                                ))}
                            </div>
                            <div className={`pdt6x pdb0x ${classes.dashedBox}`}>
                                <div className={classes.tl}>
                                    {_.map(arrOtherComment, (item, i) => (
                                        <div key={i} className={classes.timeLine}>
                                            <div className={classes.containerTimeline}>
                                                <div className={classes.contentTimeline}
                                                    style={{ transform: "translateY(-6px)" }}>
                                                    <Typography
                                                        type={"p2"}
                                                        color={"secondary"}
                                                        className="display_row align-center"
                                                    >
                                                        {item?.CreateDate}
                                                    </Typography>
                                                    <Typography type={'h4'}>{_.get(item, "Content", "") || ""}</Typography>
                                                </div>
                                            </div>

                                        </div>))
                                    }
                                </div>
                            </div>
                        </div>
                        {(i + 1) === loadOtherEvaluation.length ? "" : <Divider />}
                    </FormGroup>
                )
            }
        ))
    }

    renderGeneralEvaluation = () => {
        const { loadOtherEvaluation } = this.props;
        const { dataUserEvaluation } = this.state;
        const arrEvaluation = [...dataUserEvaluation, ..._.get(loadOtherEvaluation, "[0]Evaluation", [])];
        const arrArrgEpoint = _.reduce(arrEvaluation, (acc, itemCurrent) => {
            let index = _.findIndex(acc, o => o.EvaluationElementID === itemCurrent.EvaluationElementID);
            if (index === -1) acc = [...acc, { ...itemCurrent, divisor: 1 }];
            if (index !== -1) {
                acc[index] = {
                    ...acc[index],
                    EPoint: Number(_.get(acc, `[${index}].EPoint`, 0)) + Number(_.get(itemCurrent, "EPoint", 0)),
                    divisor: Number(_.get(acc, `[${index}].divisor`, 0)) + 1
                };
            }
            return acc;
        }, []);
        return (
            <div className="display_col full_w">
                {_.map(arrArrgEpoint, (item, i) => {
                    const arrgEpoint = item.EPoint / item.divisor;
                    return (
                        <div key={`Evaluation-${i}`} className="full_w display_col mgb3x">
                            <div >
                                <div className="jus-between align-center">
                                    <Typography
                                        type={"p1"}
                                        color={"secondary"}
                                    >
                                        {`${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", "") || 1})`}
                                    </Typography>
                                    <div className="display_row align-center">
                                        <Typography
                                            type={"h3"}
                                            color={"primary"}
                                        >
                                            {arrgEpoint.toFixed(1)}
                                        </Typography>
                                        /
                                        <Typography
                                            type={"p1"}
                                            color={"secondary"}
                                        >
                                            5.0
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <LinearProgress
                                determinate
                                color={"warning"}
                                percent={arrgEpoint * 20}
                            />
                        </div>
                    )
                })
                }
            </div>
        )
    }

    closePopup = () => {
        this.setState({ showW25F2040Popup: false });

        this.setState({ isLoadingCandidateInfo: true }, () => {
            this.loadInfoCandidate();
        })
    };

    handleOpenPopup = (name, flag = false, data) => {
        switch (name) {
            case 'sendMail':
                if (!flag) {
                    setTimeout(() => {
                        Config.notify.show('success', Config.lang("Luu_thanh_cong"), data ? 4000 : 2000);
                        this.setState({ isLoadingCandidateInfo: true }, () => {
                            this.loadInfoCandidate();
                        });
                    }, data ? 2000 : 0);
                }
                this.setState({ isOpenSendMailPop: flag, currentStage: data ?? {} });
                break;
            default:
                break;
        }
    };

    render() {
        const classes = useStyles();
        const {
            loadStage,
            loadInfoCandidate,
            loadUserEvaluation,
            loadOtherEvaluation,
            isViewOnly,
        } = this.props;
        const {
            anchorEl,
            isSaving,
            isLoadingCandidateInfo,
            isLoadingUserEvaluation,
            isLoadingAction,
            isReadOnly,
            isOpenSendMailPop,
            currentStage,
        } = this.state;
        const stageID = _.get(loadInfoCandidate, "[0]StageID", _.get(this.props, "data.StageID", ""));
        const isApprovalOffer = _.get(loadInfoCandidate, "[0]IsApprovalOffer", "");
        const iStateIDNext = _.findIndex(loadStage, o => o.StageID === stageID) + 1;
        const getStateIDNext = _.get(loadStage, `[${iStateIDNext}]`, false);
        const arrOfStageBtn = _.filter(
            [
                {
                    stageID: "REJECTED",
                    name: Config.lang("Loai_nhanh"),
                    icon: "PersonRemoved",
                    color: "danger",
                    isVisible: stageID !== "empty" && !(stageID === "HIRED" || stageID === "REJECTED")
                },
                {
                    stageID: getStateIDNext ? getStateIDNext.StageID : "",
                    name: Config.lang("Chuyen_tiep"),
                    icon: "FastForward",
                    color: "success",
                    isVisible: stageID !== "empty" && !(stageID === "HIRED" || stageID === "REJECTED")
                },
                {
                    stageID: null,
                    name: this.getStageName("HIRED"),
                    icon: "Approval",
                    color: "success",
                    isVisible: stageID !== "empty" && stageID === "HIRED"
                },
                {
                    stageID: null,
                    name: this.getStageName("REJECTED"),
                    icon: "Approval",
                    color: "danger",
                    isVisible: stageID !== "empty" && stageID === "REJECTED"
                }
            ],
            "isVisible"
        );
        const userEvaluation = _.filter(_.map([...loadOtherEvaluation, ...loadUserEvaluation], itemUser => ({
            ...itemUser,
            isEmptyEvaluation: _.some(
                _.get(itemUser, "Evaluation", []),
                item => _.isEmpty(_.get(item, "Notes", "")) && _.isNil(_.get(item, "EPoint", null))
            )
        })), item => !Boolean(item.isEmptyEvaluation));
        const isMovedAndEmployee = !!_.get(loadInfoCandidate, "[0]IsMoved", 0) || !!_.get(loadInfoCandidate, "[0]IsEmployee", 0);
        const RecInfoID = _.get(loadInfoCandidate, "[0]RecInfoID", "");
        const CandidateID = _.get(loadInfoCandidate, "[0]CandidateID", "");
        const DecisionID = _.get(loadInfoCandidate, "[0]DecisionID", "");
        const ApproveLevel = _.get(loadInfoCandidate, "[0]ApproveLevel", 0);
        const ApprovalStatus = _.get(loadInfoCandidate, "[0]ApprovalStatus", 0);
        const isApproving = (ApproveLevel > 1 && ApprovalStatus === 0);
        return (
            <div className="display_col" style={{ gap: spacing(4) }}>
                <W25F2040
                    open={this.state.showW25F2040Popup}
                    onClose={this.closePopup}
                    RecInfoID={RecInfoID || ""}
                    CandidateID={CandidateID || ""}
                    TransID={DecisionID || ""}
                    mode={0}
                    readOnly={(ApproveLevel !== 1 || ApprovalStatus !== 0)}
                    fullCandidateInfo={this.props.fullCandidateInfo}
                />
                <Card className={`${classes.boxShadowCard} full_w`}>
                    <CardBody>
                        {this.renderCandidateProfile()}
                        <div className={`jus-between ${classes.btnStage}`}>
                            {_.map(arrOfStageBtn, (item, i) => (
                                <div key={i} className="full_w mgh2x">
                                    <Button
                                        className="full_w"
                                        label={item.name}
                                        viewType="filled"
                                        color={item.color}
                                        onClick={() => this.onClickAction(item.stageID)}
                                        startIcon={item.icon}
                                        disabled={isSaving || isLoadingCandidateInfo || isLoadingAction || isMovedAndEmployee || isReadOnly || isApproving || isViewOnly}
                                    />
                                </div>
                            ))}
                            <div className="full_w mgh2x">
                                <Button
                                    className="full_w"
                                    label={Config.lang("Lua_chon")}
                                    endIcon={"ArrowDown"}
                                    disabled={isReadOnly || isSaving || isLoadingCandidateInfo || isLoadingAction || isMovedAndEmployee || isApproving || isViewOnly || (isApprovalOffer && stageID === "HIRED")}
                                    viewType="outlined"
                                    color="primary"
                                    onClick={this.onOpenToggleMenuButton}
                                />
                                <Popover
                                    anchor={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.onCloseToggleMenuButton}
                                >
                                    {_.map(loadStage, (item, i) => {
                                        const getStageID = _.get(item, "StageID", "");
                                        const getStageName = _.get(item, "StageName", "");
                                        return (
                                            <Button
                                                disabled={item.StageID === stageID}
                                                key={`menuButton-${i}`}
                                                onClick={() => this.onCloseToggleMenuButton(item.StageID)}
                                                style={{justifyContent: 'flex-start'}}
                                                className="display_row"
                                            >
                                                <div className="display_row">
                                                    <Icons
                                                        height={16}
                                                        width={16}
                                                        viewBox={false}
                                                        name={"Point"}
                                                        style={{ padding: spacing([1, 2, 1, 0]) }}
                                                        color={getStageID === "REJECTED" ? "danger" : "success"}
                                                    />
                                                    <div className="display_row align-center">
                                                        <Typography type={"h4"}>
                                                            {getStageID === "REJECTED" ? getStageID : `${i + 1} / ${loadStage.length}:`}
                                                        </Typography>
                                                        <Typography type={"p2"}
                                                            style={{
                                                                paddingLeft: spacing(),
                                                                whiteSpace: "pre-line"
                                                            }}>
                                                            {getStageName}
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </Button>
                                        )
                                    }
                                    )}
                                </Popover>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Accordion expand>
                    <AccordionSummary
                        expandIconAt={"end"}
                    >
                        <Typography type="h1">
                            {Config.lang("Ghi_chu_phong_van")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.renderUserEvaluation()}
                        <Button
                            text={Config.lang("Luu")}
                            color={"info"}
                            disabled={isLoadingUserEvaluation || isSaving || isMovedAndEmployee || isReadOnly || isApproving || isViewOnly}
                            startIcon={"save"}
                            viewType={"filled"}
                            size={"large"}
                            className={"pull-right mgb4x"}
                            onClick={this.onStore}
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion expand>
                    <AccordionSummary
                        expandIconAt={"end"}
                    >
                        <Typography type="h1">
                            {Config.lang("Tong_hop_danh_gia")}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.renderGeneralEvaluation()}
                    </AccordionDetails>
                </Accordion>
                <Accordion expand>
                    <AccordionSummary
                        expandIconAt={"end"}
                    >
                        <Typography type="h1">{`${Config.lang(
                            "Tat_ca_danh_gia"
                        )} (${_.size(userEvaluation)})`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.renderOtherEvaluation(userEvaluation)}
                    </AccordionDetails>
                </Accordion>
                {isOpenSendMailPop && <SendMailPop open={isOpenSendMailPop} currentStage={currentStage}
                    handleOpenPopup={this.handleOpenPopup} />}
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (_.get(this.props, "data.CandidateID", "") !== _.get(prevProps, "data.CandidateID", "")) {
            this.setState({
                isLoadingCandidateInfo: true,
                isLoadingUserEvaluation: true,
                isLoadingOtherEvaluation: true,
            });
            this.loadInfoCandidate();
            this.loadStage();
            this.loadUserEvaluation();
            this.loadOtherEvaluation();
        }
        if (_.get(this.props, "recInfoIDChange", "") !== _.get(prevProps, "recInfoIDChange", "")) {
            this.setState({
                isLoadingCandidateInfo: true,
            });
            this.loadInfoCandidate();
        }
    }

    componentDidMount = () => {
        this.setState({
            isLoadingCandidateInfo: true,
            isLoadingUserEvaluation: true,
            isLoadingOtherEvaluation: true,
        });
        this.loadInfoCandidate();
        this.loadStage();
        this.loadUserEvaluation();
        this.loadOtherEvaluation();
    }
        ;

    componentWillUnmount = () => {
        this.oldDataUserComment = null;
        clearTimeout(this.timerCandidateInfo);
        clearTimeout(this.timerUserEvaluaton);
        clearTimeout(this.timerOtherEvaluation);
    }
}

W25F2012.propTypes = {
    data: PropTypes.object
};

W25F2012.defaultProps = {
    isViewOnly: false,
};

export default compose(connect(state => ({
    getCboAppStatus: state.general.getCboAppStatus,
    loadStage: state.W25F2012.loadStage,
    loadInfoCandidate: state.W25F2012.loadInfoCandidate,
    loadUserEvaluation: state.W25F2012.loadUserEvaluation,
    loadOtherEvaluation: state.W25F2012.loadOtherEvaluation,
}),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w25f2012Actions: bindActionCreators(W25F2012Actions, dispatch)
    })))(W25F2012);
