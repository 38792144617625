/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 10/06/2020
 * @Example
 */

import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import * as W25F2015Actions from "../../../../redux/W2X/W25F2015/W25F2015_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import ColumnItem from "./utils/ColumnItem";
import GridContainer from '../../../grid-container/grid-container'
import UserImage from '../../../common/user/user-image';
import UserName from "../../../common/user/user-name";
import Config from "../../../../config";
import {Column} from "devextreme-react/data-grid";
import Step from "./utils/Step";
import History from "../../../libs/history";
import _ from 'lodash'
import W25F2022 from "../W25F2022/W25F2022";
import {browserHistory} from "react-router";
import {Rating, Icon, ButtonIcon, Tooltip, Typography, Button, Divider} from 'diginet-core-ui/components'
import {makeStyles} from 'diginet-core-ui/theme'
import PropTypes from "prop-types";

const useStyle = makeStyles(theme=>({
    userImage: {
        height: 24,
        width: 24,
        marginRight: 10
    },
    customPadding: {
        marginRight: 15,
        marginLeft: 15,
        '& .dx-datagrid-headers': {
            '& td': {
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
            }
        }
    },
    iconColumn: {
        position: "absolute",
        right: 0,
        color: "#7F828E"
    }
}));

class W25F2017Candidate extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tasks: {},
            columns: {},
            columnOrder: [],
            loadData: false,
            dataSource: [],
            showW25F2022Popup: false,
            data: {},
        };
        this.isDown = false;
        this.startX = null;
        this.scrollLeft = null;
        this.el = null;
    }

    onView = data => {
        const { dataListCandidate } = this.props;
        const iSelected = _.findIndex(dataListCandidate, ["CandidateID", data.CandidateID]);
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2023",
            state: {
                iSelected,
                dataListCandidate: _.map(dataListCandidate, item =>
                    _.pick(item, ["CandidateID", "RecInfoID", "InterviewFileID"])
                ),
            },
        });
    };

    renderEmpProfile = e => {
        // const { classes } = this.props;
        const { data } = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage
                    alt=""
                    fieldImage={"CandidatePictureURL"}
                    data={data}
                    onClick={() => this.onView(data)}
                    className={"mgr1x"}
                    allowHover
                />
                <div className={"display_col"}>
                    <Typography type={"h3"}>
                        <UserName renderItem={data.CandidateName} onClick={() => this.onView(data)} allowHover />
                    </Typography>
                    <Typography>{`${data.SexName}, ${Config.convertDate(data.BirthDate, '', "DD/MM/YYYY")}`}</Typography>
                </div>
            </div>
        );
    };

    renderListMemberFollow = e => {
        const { data } = e.row;
        const { ListMemberFollow } = data;
        const listMember = ListMemberFollow.split(",");
        // get list user by UserID
        const listUsers = Config.getListUser(listMember, "UserID");
        // const listUsers = Config.getListUser(["V0001","TS11","NL0002","PCE00037"], "EmployeeID");
        return (
            <div className={"display_row align-center"}>
                {listUsers.map((item, i) => {
                    if (i > 2) return null;

                    // nhiều hơn 2 hiện ...
                    if (i === 2) return <Icon name={"MoreHoriz"} width={24} height={24} />;

                    return <UserImage key={i} data={item} width={24} height={24} />;
                })}
            </div>
        );
    };

    renderInfo = e => {
        const { data } = e.row;
        const { Email, Mobile } = data;
        return (
            <div>
                <div className=" display_row align-center mg5e">
                    <Icon name={"mail"} color={"dark"} width={16} height={16} className="pdr5" />
                    <Typography lineClamp={1} hoverTooltip>
                        {Email || ""}
                    </Typography>
                </div>
                <div className={" display_row align-center"}>
                    <Icon name={"phone"} color={"dark"} width={16} height={16} className="pdr5" />
                    <Typography lineClamp={1} hoverTooltip>
                        {Mobile || ""}
                    </Typography>
                </div>
            </div>
        );
    };
    renderStage = e => {
        const { dataStage } = this.props;
        const { data } = e.row;
        const { StageID, CandidateID } = data;
        return (
            <div>
                {StageID !== "REJECTED" ? (
                    <Step dataStage={dataStage} StageID={StageID} key={CandidateID} />
                ) : (
                    <div className="display_row align-center">
                        <Divider height={14} width={4} color={"danger"}/>
                        <Typography color={'danger'} type={'h3'} className={'mgl1x'}>{StageID}</Typography>
                    </div>
                )}
            </div>
        );
    };
    // componentDidMount() {
    //     this.props.loadListCandidate(1);
    // }
    renderReview = e => {
        const { data } = e.row;
        const { count, EPointAvg } = data;
        return (
            <div>
                <Rating
                    color={'warning'}
                    max={5}
                    value={Number.isInteger(EPointAvg) ? EPointAvg : Math.ceil(EPointAvg) - 0.5}
                    half={0.5}
                    readOnly
                    size={"large"}
                    className="pdb5"
                />
                <div className="display_row align-center">
                    <Typography type={"h2"}>{EPointAvg ?? 0}/</Typography>
                    <Typography type={"h2"} color={"dark5"}>
                        5.0
                    </Typography>
                    <Typography className={"mgh1x"}>-</Typography>
                    <Typography type={"h3"} color={"info"} className={"mgr1x"}>
                        {count}
                    </Typography>
                    <Typography>đề nghị tuyển</Typography>
                </div>
            </div>
        );
    };

    renderCV = e => {
        const { data } = e.row;
        return (
            <Button
                viewType={"link"}
                onClick={() => {
                    this.setState({ showW25F2022Popup: true, data });
                }}
                color={"info"}
                label={Config.lang("xem_CV")}
            />
        );
    };

    renderEdit = e => {
        const { onEdit } = this.props;
        return (
            <Tooltip title={Config.lang("Sua")} style={{ marginLeft: "auto" }}>
                <ButtonIcon viewType={"text"} name={"Edit"} circular className={"mgr1x"} onClick={() => onEdit(e)} />
            </Tooltip>
        );
    };

    renderRemove = e => {
        return (
            <Tooltip title={Config.lang("Xoa")} style={{marginLeft: 'auto'}}>
                <ButtonIcon
                    viewType={"text"}
                    name={"delete"}
                    circular
                    className={"mgr1x"}
                    onClick={() => this.onRemove(e)}
                />
            </Tooltip>
        );
    };
    //async
    saveHistoryCandidate = async eData => {
        const { RecInfoTitle } = this.props;
        const { TransID, CandidateID, CandidateName } = eData;
        if (TransID) {
            const data = {
                RecInfoTitle: !_.isNull(RecInfoTitle) ? RecInfoTitle : "",
                CandidateID: `${CandidateID || ""} - ${CandidateName || ""}`,
            };

            const captions = {
                RecInfoTitle: null,
                CandidateID: "Ung_vien",
            };

            const options = {
                data: data,
                captions: captions,
                action: 3,
                ModuleID: "W25",
                TransID: "TransID",
                TransactionID: "RecruitmentInfo",
                TransactionName: "Tin tuyển dụng",
            };
            const history = new History(options);
            // const allData = await history.get();
            // console.log('test', allData);
            await history.save();
            // let el = {};
            // let data = [];
            // el.ModuleID = "W25";
            // el.TransactionID = "RecruitmentInfo";
            // el.TransactionName = "Tin tuyển dụng:";
            // el.TransID = TransID;
            // el.Content = {
            //     data_RecInfoTitle: RecInfoTitle,
            //     [`${Config.lang("Ung_vien")}`]: CandidateID + "-" + CandidateName
            // };
            // el.Action = 3;
            // el.UserID = "LEMONADMIN", -- UserID
            // el.createdAt = "2020-08-11T04:18:57.985Z",
            // el.updatedAt = "2020-08-11T04:18:57.985Z"
            // data.push(el);
            // const params = {
            //     attributes: JSON.stringify(data),
            // };
            // await this.props.W25F2015Actions.addHistory(params, (error) => {
            //     status = error ? 400 : 200
            // });
            // console.log(params);
        } else {
            Config.notify.show("error", Config.lang("Luu_lich_su_khong_thanh_cong"), 2000);
        }
    };
    onRemove = async e => {
        const { data } = e.row;
        const { RecInfoID, CandidateID } = data;
        // const dataSource = this.dataGrid
        //     ? this.dataGrid.instance.option("dataSource")
        //     : this.props.dataListCandidate;
        let params = {
            RecInfoID,
            CandidateID,
            FormID: "W25F2017",
            Language: Config.language || "84",
        };
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_xoa_ung_vien_khoi_tin_tuyen_dung"), () => {
            (async () => {
                await this.props.W25F2015Actions.deleteCandidate(params, async (error, deleteData) => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (deleteData.Status === 1) {
                        let DeleteToken = { DeleteToken: deleteData.DeleteToken };
                        await Config.popup.show(
                            "YES_NO",
                            Config.lang("Da_ton_tai_lich_phong_van_cua_ung_cu_vien_ban_co_muon_xoa_khong"),
                            () => {
                                (async () => {
                                    await this.props.W25F2015Actions.deleteConfirm(DeleteToken, async (error, val) => {
                                        if (error) {
                                            Config.popup.show("ERROR", error);
                                            return false;
                                        }
                                        if (val) {
                                            await this.saveHistoryCandidate(data);
                                            await this.props.loadListCandidate(1);
                                        }
                                    });
                                })();
                            }
                        );
                    } else if (deleteData.Status === 0) {
                        await this.saveHistoryCandidate(data);
                        await this.props.loadListCandidate(1);
                    }
                });
            })();
        });
    };

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.updateStage) !== JSON.stringify(prevProps.updateStage)) {
            this.props.loadListCandidate(1);
        }
    }

    //Grab and scroll
    mousedown = e => {
        this.isDown = true;
        // this.el.classList.add('active');
        this.startX = e.pageX - this.el.offsetLeft;
        this.scrollLeft = this.el.scrollLeft;
        this.el.style.cursor = "grabbing";
        this.el.style.userSelect = "none";
    };
    mouseleave = () => {
        this.isDown = false;
        // this.el.classList.remove('active');
        this.el.style.cursor = "grab";
        this.el.style.removeProperty("user-select");
    };

    mouseup = () => {
        this.isDown = false;
        // this.el.classList.remove('active');
        this.el.style.cursor = "grab";
        this.el.style.removeProperty("user-select");
    };
    mousemove = e => {
        let isDown = this.isDown,
            scrollLeft = this.scrollLeft,
            startX = this.startX;
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - this.el.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        this.el.scrollLeft = scrollLeft - walk;
        // console.log(walk);
    };

    componentDidMount() {
        if (this.el && !Config.isMobile) {
            this.el.addEventListener("mousedown", e => {
                this.mousedown(e);
            });
            this.el.addEventListener("mouseleave", () => {
                this.mouseleave();
            });
            this.el.addEventListener("mouseup", () => {
                this.mouseup();
            });
            this.el.addEventListener("mousemove", e => {
                this.mousemove(e);
            });
        }
    }

    componentWillUnmount() {
        if (this.el && !Config.isMobile) {
            this.el.removeEventListener("mousedown", e => {
                this.mousedown(e);
            });
            this.el.removeEventListener("mouseleave", () => {
                this.mouseleave();
            });
            this.el.removeEventListener("mouseup", () => {
                this.mouseup();
            });
            this.el.removeEventListener("mousemove", e => {
                this.mousemove(e);
            });
        }
    }

    closePopup = key => {
        this.setState({ [key]: false });
    };

    render() {
        const { dataListCandidate, updateStage, dataStage, loadingListCandidate } = this.props;
        const { tasks, columns, columnOrder } = updateStage;
        const { showW25F2022Popup, data } = this.state;
        let totalDataListCandidate = dataListCandidate.length;
        let dataStageLength = dataStage.length;
        const classes = useStyle();
        return (
            <div style={{ width: "100%" }}>
                <W25F2022
                    openPopup={showW25F2022Popup}
                    data={data}
                    onClosePopup={() => {
                        this.closePopup("showW25F2022Popup");
                    }}
                />
                <div
                    style={{ width: "100%", overflow: "auto", cursor: "grab" }}
                    ref={elem => (this.el = elem)}
                    className="hidden-scrollbar"
                    id="column-container"
                >
                    <div className="display_row" style={{ minWidth: 1024 }}>
                        <div
                            style={{ minWidth: `calc(100% / ${dataStageLength + 1}`, height: 40, position:'relative', }}
                            ref="list-column"
                            className="display_row align-center valign-middle"
                        >
                            <Typography color={"info"} type={"h3"} className="mgr2x">
                                {totalDataListCandidate}
                            </Typography>
                            <Typography color={"dark6"} lineClamp={1} hoverTooltip type={"h3"} uppercase>
                                {Config.lang("Ung_vien")}
                            </Typography>
                            {dataStage.length > 0 && <Icon name={"ArrowRight"} style={{position:'absolute', right:0 }}/>}
                        </div>
                        {columnOrder &&
                            columnOrder.map((columnId, idx) => {
                                const column = { ...columns }[columnId];
                                if (!column || !column.taskIds) return null;
                                const tasksL = column.taskIds.map(taskId => tasks[taskId]);
                                return (
                                    <ColumnItem
                                        isDropable={false}
                                        key={column.id}
                                        index={idx + 1}
                                        column={column}
                                        tasks={tasksL}
                                        loadDetail={this.loadDetail}
                                        // indexTab={1}
                                        // candidateLength={totalDataListCandidate}
                                        dataStageLength={dataStageLength + 1}
                                        // EmployeeID={EmployeeID}
                                    />
                                );
                            })}
                    </div>
                </div>
                <Divider/>
                <div className={classes.customPadding + " pdt10"}>
                    <GridContainer
                        reference={ref => (this.dataGrid = ref)}
                        dataSource={dataListCandidate}
                        loading={loadingListCandidate}
                        showBorders={false}
                        style={{ paddingBottom: 37 }}
                        keyExpr={"CandidateID"}
                        typePaging={"normal"}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        // showRowLines={false}
                        rowAlternationEnabled={false}
                        height={Config.getHeightGrid() - 142}
                    >
                        <Column caption={Config.lang("Ung_vien")} cellRender={this.renderEmpProfile} minWidth={235} />
                        <Column
                            caption={Config.lang("Nguoi_quan_ly")}
                            cellRender={this.renderListMemberFollow}
                            width={175}
                        />
                        <Column caption={Config.lang("Thong_tin_lien_he")} cellRender={this.renderInfo} width={245} />
                        <Column caption={Config.lang("Giai_doanF")} cellRender={this.renderStage} width={245} />
                        <Column caption={Config.lang("Nguon_ung_vien")} dataField={"RecSourceName"} width={200} />
                        <Column caption={Config.lang("Danh_gia")} cellRender={this.renderReview} width={200} />
                        <Column caption={Config.lang("Thoi_gian")} alignment={"center"} cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("Ung_tuyen")}
                                dataField={"ApplyDate"}
                                alignment={"center"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                                width={145}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column caption={Config.lang("Thoi_gian")} alignment={"center"} cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("Phong_van")}
                                dataField={"InterviewDate"}
                                alignment={"center"}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                                width={145}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column
                            caption={Config.lang("Ly_do_tu_choi_2")}
                            dataField={"RejectReason"}
                            // width={200}
                        />
                        <Column
                            caption={Config.lang("xem_CV")}
                            alignment={"center"}
                            cellRender={this.renderCV}
                            width={100}
                        />
                        <Column
                            caption={Config.lang("Sua")}
                            alignment={"right"}
                            cellRender={this.renderEdit}
                            width={100}
                        />
                        <Column
                            caption={Config.lang("Xoa")}
                            alignment={"right"}
                            cellRender={this.renderRemove}
                            width={100}
                        />
                    </GridContainer>
                </div>
            </div>
        );
    }
}

W25F2017Candidate.propTypes = {
    dataListCandidate: PropTypes.array,
    dataStage: PropTypes.array,
    updateStage: PropTypes.object,
    loadingListCandidate: PropTypes.bool
};
export default compose(
    connect(
        state => ({}),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch)
        })
    )
)(W25F2017Candidate);
