/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/16/2020
 * @Example
 */
import React                         from "react";
import PropTypes                     from "prop-types";
import {FormGroup, Col, Row}         from "react-bootstrap";
import Config                        from "../../../../../config";
import {Combo, TextField}            from "../../../../common/form-material";
import DateBoxPicker                 from "../../../../common/form-material/date-box";
import {bindActionCreators, compose} from "redux";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../../redux/W2X/W25F2011/W25F2011_actions";
import * as generalActions           from "../../../../../redux/general/general_actions";
import withStyles                    from "@material-ui/core/styles/withStyles";
import {FormControl, InputLabel}     from "@material-ui/core";
import {Dropdown, MoneyInput}        from 'diginet-core-ui/components';

const styles = {
    customMoneyInput: {
        position: 'relative', 
        display: 'inline-flex',
        width: '100%',
        '& > label.DGN-UI-Typography': {
            position: 'absolute',
        },
        '& > div.DGN-UI-InputBase': {
            marginTop: 16
        }
    }
};
class W25F2011Expand1 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.filterCboProjects = {
            skip: 0,
            limit: 50,
            strSearch: ""
        };
    }

    handleChange = (key, e) => {
        if (!key || !e) return false;
        const {onChange} = this.props;
        if (onChange) onChange(key, e);
    };

    onValidate = (key, e) => {
        const {onValidate} = this.props;
        if (onValidate) onValidate(key, e);
    };

    render() {
        const { classes, error, dataForm, getCboDepartments, getCboWorkingType, getCboDuty2,
                  getLoadCurrency, getCboRecruitmentFile, dataCboProjects, loadCboProjects } = this.props;
        const { disabled, loading} = this.state;

        return (
            <div style={{width: "100%"}}>
                <Row>
                    <Col xs={12} sm={12} md={9} lg={9}>
                        <FormGroup>
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <TextField
                                        error={error && error['RecInfoTitle']}
                                        label={Config.lang('Tieu_de_tin_tuyen_dung')}
                                        variant={"standard"}
                                        margin={"normal"}
                                        value={dataForm && dataForm.RecInfoTitle}
                                        required
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onBlur={(e) => this.onValidate("RecInfoTitle", e)}
                                        onChange={(e) => this.handleChange('RecInfoTitle', e)}
                                        fullWidth
                                        multiline
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <Combo
                                        error={error && error['RecruitmentFileID']}
                                        dataSource={Config.storeDataSoureDevExtreme(getCboRecruitmentFile)}
                                        displayExpr={'RecruitmentFileName'}
                                        valueExpr={'RecruitmentFileID'}
                                        margin={'normal'}
                                        stylingMode={'underlined'}
                                        disabled={loading || disabled}
                                        value={dataForm?.RecruitmentFileID ?? ''}
                                        shrink
                                        selectProps={{
                                            onFocusOut: (e) => this.onValidate('RecruitmentFileID', e)
                                        }}
                                        label={Config.lang('De_xuat_tuyen_dung')}
                                        onValueChanged={(e) => this.handleChange('RecruitmentFileID', e)}
                                        showClearButton
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Combo
                                        error={error && error['RecPositionID']}
                                        dataSource={Config.storeDataSoureDevExtreme(getCboDuty2)}
                                        displayExpr={'DutyName'}
                                        valueExpr={'DutyID'}
                                        margin={"normal"}
                                        required
                                        stylingMode={'underlined'}
                                        disabled={loading || disabled || !!dataForm?.RecruitmentFileID}
                                        value={dataForm && dataForm.RecPositionID}
                                        shrink={true}
                                        itemRender={(item) => {
                                            return item.DutyID + " - " + item.DutyName;
                                        }}
                                        selectProps={{
                                            onFocusOut: (e) => this.onValidate("RecPositionID", e)
                                        }}
                                        label={Config.lang('Vi_tri_tuyen_dung')}
                                        onValueChanged={(e) => this.handleChange("RecPositionID", e)}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Combo
                                        error={error && error['DepartmentID']}
                                        dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                        displayExpr={'DepartmentName'}
                                        valueExpr={'DepartmentID'}
                                        margin={"normal"}
                                        required
                                        stylingMode={'underlined'}
                                        disabled={loading || disabled || !!dataForm?.RecruitmentFileID}
                                        value={dataForm && dataForm.DepartmentID}
                                        shrink={true}
                                        selectProps={{
                                            onFocusOut: (e) => this.onValidate("DepartmentID", e)
                                        }}
                                        label={Config.lang('Phong_ban')}
                                        onValueChanged={(e) => this.handleChange("DepartmentID", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <FormControl variant={"standard"}
                                                 margin={"normal"}
                                                 error={!!(error && (error['SalaryFrom'] || error['SalaryTo']))}
                                                 fullWidth={true}>
                                        <InputLabel
                                            shrink={true}
                                            required={true}
                                        >
                                            {Config.lang("Muc_luong")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <MoneyInput
                                                        className={classes.customMoneyInput}
                                                        viewType={'underlined'}
                                                        onBlur={(e) => this.onValidate("SalaryFrom", e)}
                                                        onChange={e => this.handleChange("SalaryFrom", e)}
                                                        required
                                                        allowZero
                                                        disabledNegative
                                                        thousandSeparator
                                                        placeholder={Config.lang("Muc_luong_tu")}
                                                        value={dataForm?.SalaryFrom ?? ''}
                                                        validates={{
                                                            name : "max",
                                                            compareValue : dataForm?.SalaryTo ?? 0,
                                                            message : Config.lang("Luong_tu_phai_nho_hon_luong_den"),
                                                        }}
                                                        error={error && error['SalaryFrom']}
                                                        style={{ margin: '16px 0 8px' }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <MoneyInput
                                                        className={classes.customMoneyInput}
                                                        viewType={'underlined'}
                                                        onBlur={(e) => this.onValidate("SalaryTo", e)}
                                                        onChange={e => this.handleChange("SalaryTo", e)}
                                                        required
                                                        allowZero
                                                        disabledNegative
                                                        thousandSeparator
                                                        placeholder={Config.lang("Muc_luong_den")}
                                                        value={dataForm?.SalaryTo ?? ''}
                                                        validates={{
                                                            name : "min",
                                                            compareValue : dataForm?.SalaryFrom ?? 0,
                                                            message : Config.lang("Luong_tu_phai_nho_hon_luong_den"),
                                                        }}
                                                        error={error && error['SalaryTo']}
                                                        style={{ margin: '16px 0 8px' }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <Combo
                                                    error={error && error['CurrencyID']}
                                                    dataSource={Config.storeDataSoureDevExtreme(getLoadCurrency)}
                                                    displayExpr={'CurrencyName'}
                                                    valueExpr={'CurrencyID'}
                                                    margin={"normal"}
                                                    required
                                                    stylingMode={'underlined'}
                                                    disabled={loading || disabled}
                                                    value={dataForm && dataForm.CurrencyID ? dataForm.CurrencyID : "VND"}
                                                    shrink={true}
                                                    selectProps={{
                                                        onFocusOut: (e) => this.onValidate("CurrencyID", e)
                                                    }}
                                                    onValueChanged={(e) => this.handleChange("CurrencyID", e)}
                                                />
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <Combo
                                                    dataSource={[{ID: 0, Name: Config.lang("Khong_hien_muc_luong")}, {ID: 1, Name: Config.lang("Hien_muc_luong")}]}
                                                    displayExpr={'Name'}
                                                    valueExpr={'ID'}
                                                    margin={"normal"}
                                                    stylingMode={'underlined'}
                                                    disabled={loading || disabled}
                                                    value={dataForm && dataForm.IsShowSalary}
                                                    shrink={true}
                                                    selectProps={{
                                                        onFocusOut: (e) => this.onValidate("IsShowSalary", e)
                                                    }}
                                                    onValueChanged={(e) => this.handleChange("IsShowSalary", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <MoneyInput
                                        className={classes.customMoneyInput}
                                        viewType={'underlined'}
                                        onBlur={(e) => this.onValidate("NumPerson", e)}
                                        onChange={e => this.handleChange("NumPerson", e)}
                                        required
                                        allowZero
                                        disabledNegative
                                        label={Config.lang('So_luong_can_tuyen')}
                                        value={dataForm?.NumPerson ?? ''}
                                        error={error && error['NumPerson']}
                                        style={{ margin: '16px 0 8px' }}
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Combo
                                        error={error && error['WorkingTypeID']}
                                        dataSource={Config.storeDataSoureDevExtreme(getCboWorkingType)}
                                        displayExpr={'WorkingTypeName'}
                                        valueExpr={'WorkingTypeID'}
                                        margin={"normal"}
                                        required
                                        stylingMode={'underlined'}
                                        disabled={loading || disabled}
                                        value={dataForm && dataForm.WorkingTypeID}
                                        shrink={true}
                                        label={Config.lang('Loai_hinh_cong_viec')}
                                        selectProps={{
                                            onFocusOut: (e) => this.onValidate("WorkingTypeID", e)
                                        }}
                                        onValueChanged={(e) => this.handleChange("WorkingTypeID", e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <FormControl variant={"standard"}
                                                 margin={"normal"}
                                                 fullWidth={true}>
                                        <InputLabel
                                            shrink={true}
                                            required={true}
                                        >
                                            {Config.lang("Thoi_gian_nop_don")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <DateBoxPicker
                                                        placeholder={Config.lang("Chon_ngay_tu")}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        width={"100%"}
                                                        stylingMode={"underlined"}
                                                        shrink={true}
                                                        onValueChanged={e => this.handleChange("ReceiveDateFrom", e)}
                                                        margin={"normal"}
                                                        max={dataForm && dataForm.ReceiveDateTo}
                                                        value={dataForm && dataForm.ReceiveDateFrom}
                                                        disabled={disabled || loading}
                                                        error={error && error["ReceiveDateFrom"]}
                                                        dateBoxProps={{
                                                            onFocusOut: (e) => this.onValidate("ReceiveDateFrom", e)
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <DateBoxPicker
                                                        placeholder={Config.lang("Chon_ngay_den")}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        width={"100%"}
                                                        stylingMode={"underlined"}
                                                        onValueChanged={e => this.handleChange("ReceiveDateTo", e)}
                                                        margin={"normal"}
                                                        min={dataForm && dataForm.ReceiveDateFrom}
                                                        value={dataForm && dataForm.ReceiveDateTo}
                                                        disabled={disabled || loading}
                                                        error={error && error["ReceiveDateTo"]}
                                                        dateBoxProps={{
                                                            onFocusOut: (e) => this.onValidate("ReceiveDateTo", e)
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <FormControl variant={"standard"}
                                                 margin={"normal"}
                                                 error={!!(error && (error['AgeFrom'] || error['AgeTo']))}
                                                 fullWidth={true}>
                                        <InputLabel shrink >
                                            {Config.lang("Do_tuoi")}
                                        </InputLabel>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <MoneyInput
                                                        className={classes.customMoneyInput}
                                                        viewType={'underlined'}
                                                        onBlur={(e) => this.onValidate("AgeFrom", e)}
                                                        onChange={e => this.handleChange("AgeFrom", e)}
                                                        allowZero
                                                        disabledNegative
                                                        placeholder={Config.lang("Tu")}
                                                        value={dataForm?.AgeFrom ?? ''}
                                                        validates={{
                                                            name : "max",
                                                            compareValue : dataForm?.AgeTo ?? 0,
                                                            message : Config.lang("Tuoi_tu_phai_nho_hon_tuoi_den"),
                                                        }}
                                                        error={error && error['AgeFrom']}
                                                        style={{ marginBottom: 0 }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <div className={"display_row align-right"}>
                                                    <MoneyInput
                                                        className={classes.customMoneyInput}
                                                        viewType={'underlined'}
                                                        onBlur={(e) => this.onValidate("AgeTo", e)}
                                                        onChange={e => this.handleChange("AgeTo", e)}
                                                        allowZero
                                                        disabledNegative
                                                        placeholder={Config.lang("Den")}
                                                        value={dataForm?.AgeTo ?? ''}
                                                        validates={{
                                                            name : "min",
                                                            compareValue : dataForm?.AgeFrom ?? 0,
                                                            message : Config.lang("Tuoi_tu_phai_nho_hon_tuoi_den"),
                                                        }}
                                                        error={error && error['AgeTo']}
                                                        style={{ marginBottom: 0 }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Dropdown
                                        allowSearch
                                        dataSource={dataCboProjects.rows}
                                        total={dataCboProjects.total}
                                        skip={this.filterCboProjects.skip}
                                        limit={this.filterCboProjects.limit}
                                        displayExpr={"ProjectName"}
                                        valueExpr={'ProjectID'}
                                        value={dataForm.ProjectID}
                                        clearAble
                                        label={Config.lang('Du_an')}
                                        placeholder={Config.lang('Chon')}
                                        onChange={(e) => this.handleChange("ProjectID", e)}
                                        onInput={(e) => {
                                            this.filterCboProjects.strSearch = e.target.value;
                                            this.filterCboProjects.skip = 0;
                                            loadCboProjects(true,
                                                {
                                                    strSearch: this.filterCboProjects.strSearch,
                                                    skip: this.filterCboProjects.skip,
                                                    limit:  this.filterCboProjects.limit
                                                });
                                        }}
                                        onLoadMore={(e) => {
                                            this.filterCboProjects.skip = e.skip;
                                            this.filterCboProjects.limit = e.limit;
                                            loadCboProjects(false,
                                                {
                                                    strSearch: this.filterCboProjects.strSearch,
                                                    skip: this.filterCboProjects.skip,
                                                    limit:  this.filterCboProjects.limit
                                                });
                                        }}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }

}

W25F2011Expand1.propTypes = {
    error: PropTypes.any,
    dataForm: PropTypes.object,
    onChange: PropTypes.func,
    dataCboProjects: PropTypes.object,
};

export default compose(connect((state) => ({
    getCboDepartments: state.general.getCboDepartments,
    getCboWorkingType: state.W25F2011.getCboWorkingType,
    getCboDuty2: state.general.getCboDuty2,
    getLoadCurrency: state.general.getLoadCurrency,
    getCboRecruitmentFile: state.W25F2011.getCboRecruitmentFile,
}), (dispatch) => ({
    W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F2011Expand1);